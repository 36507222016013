import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Icon, IconSymbol } from 'elements/components/Icons';
import bind from 'bind-decorator';
import {Spinner} from 'ui/components/Spinner/Spinner';
import { intl } from 'i18n';

interface NextButtonProps {
    id?: string;
    title: string;
    onClick: (event: React.MouseEvent) => void;
    disabled?: boolean;
}

export const NextButton: React.FunctionComponent<NextButtonProps> = ({
    id, title, onClick, disabled = false
}) => {
    return (
        <button id={id} className='primary-button' onClick={onClick} disabled={disabled}>
            <Icon name='polygon-right'/>
            {title}
        </button>
    );
};

export interface ButtonProps extends React.PropsWithChildren {
    /** Visual style of the button
     * - primary is yellow
     * - normal is blue
     * - outline is a blue outline
     * - link is a link button
     * - unstyled is just that
     * - ghost is like outline but for dark backgrounds
     */
    btnStyle?: 'primary'|'normal'|'outline'|'link'|'unstyled'|'ghost';
    href?: string;
    onClick?: () => void;
    label: MessageDescriptor;
    labelValues?: any;
    secondaryLabel?: MessageDescriptor;
    secondaryLabelValues?: any;
    icon?: IconSymbol;
    /** Icon only: the text is only for screenreaders; visually show only the icon */
    iconOnly?: boolean;
    iconPosition?: 'left'|'right';
    iconZoom?: string;
    iconOutline?: boolean;
    iconFill?: string;
    disabled?: boolean;
    /** Optionally make the button small */
    size?: 'sm';
    fullWidth?: boolean;
    /** If this is a link (has a href=""), then should it open in a new window? */
    newWindow?: boolean;
    /** To make it easier to find this button in end-to-end tests, give it a data-testid attribute */
    ['data-testid']?: string;
    /** Extra classes are to be used sparingly. Do not use when one of the
     * official common button styles (btnStyle options) could be used instead.
     */
    className?: string;
    hasLoader?: boolean;
    /** Short-circuit to make the loader icon appear. */
    forceLoading?: boolean;
}

interface State {
    isLoading: boolean;
}

/**
 * A generic button. Do not ever apply CSS rules to this button, other than the ones
 * that are defined in ui/components/Buttons/styles.scss. We want all our buttons to
 * be consistent.
 *
 * See style guide at
 * https://app.zeplin.io/project/5b7d78e144f1281a779b7799/screen/5c00ff49321dec2c78b8e964
 */
export class Button extends React.Component<ButtonProps, State> {
    constructor(props: ButtonProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }


    public static defaultProps = {
        btnStyle: 'normal' as 'normal',
        disabled: false,
        onClick: () => {/* default */},
        newWindow: false,
        iconOnly: false,
        iconPosition: 'left' as 'left',
        iconZoom: 'x1',
        hasLoader: false,
        fullWidth: false,
    };

    public render() {
        const labelText = intl.formatMessage(this.props.label, this.props.labelValues);
        const children = <>
            <span>
                {this.props.icon && this.props.iconPosition === 'left' && <Icon name={this.props.icon} outline={this.props.iconOutline} zoom={this.props.iconZoom} fill={this.props.iconFill ? this.props.iconFill : 'currentColor'}/>}
                <span {...(this.props.iconOnly ? {className: 'sr-only'} : {})}>{labelText}</span>
                {this.props.icon && this.props.iconPosition === 'right' && <Icon name={this.props.icon} outline={this.props.iconOutline} zoom={this.props.iconZoom} fill={this.props.iconFill ? this.props.iconFill : 'currentColor'}/>}
            </span>
            { this.props.secondaryLabel &&
                <span className='secondary-label'>
                    {intl.formatMessage(this.props.secondaryLabel, this.props.secondaryLabelValues)}
                </span>
            }
            {this.props.children}
        </>;

        // CSS classes:
        const classes = ['btn', `lx-btn-${this.props.btnStyle}`];
        if (this.props.btnStyle === 'link') { classes.push('btn-link'); }
        if (this.props.disabled) { classes.push('disabled'); }
        if (this.props.size) { classes.push(`btn-${this.props.size}`); }
        if (this.props.fullWidth) { classes.push('btn-full-width'); }
        classes.push(`lx-btn-${this.props.iconPosition}-icon`);
        if (this.props.className) { classes.push(this.props.className); }
        if (this.props.href) {
            // For accessibility reasons, any link that navigates to another page
            // must be an <a> element, even if it looks like a button.
            const args: Partial<NavLinkProps> = {
                'target': this.props.newWindow ? '_blank' : undefined,
                'className': classes.join(' '),
                'onClick': this.props.disabled ? (e) => { e.preventDefault(); } : this.onClick,
                'aria-disabled': this.props.disabled,
            };
            if (this.props.iconOnly) { args.title = labelText; } // Make label text a tooltip in icon only mode
            if (this.props['data-testid']) { (args as any)['data-testid'] = this.props['data-testid']; } // ID for end-to-end tests
            const href = this.props.href.toLowerCase();
            if (href.startsWith('http') || href.startsWith('mailto')) {
                // This is an external link
               return <a
                    href={this.props.href}
                    target={args.target}
                    className={classes.join(' ')}
                    onClick={args.onClick}
                    aria-disabled={args['aria-disabled']}
                    title={args.title}
                    data-testid={this.props['data-testid']}
                >{children}</a>;
            } else {
                // This is a link to another page in this application.
                return <NavLink exact to={this.props.href} {...args}>{children}</NavLink>;
            }
        } else {
            return <button
                className={'inner-internal-button ' + classes.join(' ')}
                type='button'
                disabled={this.props.disabled}
                aria-disabled={this.props.disabled}
                onClick={this.onClick}
                {...(this.props.iconOnly ? {title: labelText} : {})} // Make label text a tooltip in icon only mode
                {...(this.props['data-testid'] ? {'data-testid': this.props['data-testid']} : {})} // ID for end-to-end tests
            >
                { ((this.props.hasLoader && this.state.isLoading) || this.props.forceLoading) &&
                    <div className={'spinner-container'}><Spinner size='20px' black={true}/></div>
                }
                {children}
            </button>;
        }
    }

    @bind async onClick() {
        if (this.props.onClick) {
            this.setState({isLoading: true});
            await this.props.onClick();
            this.setState({isLoading: false});
        }
    }
}
