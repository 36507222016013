import React, { useState } from 'react';

interface Tab {
    label: string;
}

interface TabsProps {
    tabs: Tab[];
    onTabChange: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onTabChange }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        onTabChange(index);
    };

    return (
        <div className='tabs'>
            {tabs.map((tab, index) => (
                <button
                    key={index}
                    className={index === activeTab ? 'active' : ''}
                    onClick={() => handleTabChange(index)}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
