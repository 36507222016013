import React, { useEffect, useState, RefObject, createRef } from 'react';

import { CareersApi } from 'global/api';
import { Career } from 'labxchange-client';

import Navbar from '../../CareerNavbar/CareerNavbar';
import CareerHeader from '../CareerDetailsHeader/CareerDetailsHeader';
import CareerIntroduction from './CareerIntroduction';
import CareerDuties from './CareerDuties';
import RelatedCareerSection from './RelatedCareersSection';
import { Spinner } from '../../../../ui/components';
import { useParams } from 'react-router-dom';

const CareerDetailsPage: React.FC = () => {
    const { careerSlug } = useParams<{ careerSlug: string }>();

    const navLinks = [
        { name: 'Introduction', href: '#introduction' },
        { name: 'Duties', href: '#duties' },
        { name: 'Skills', href: '#skills' },
        { name: 'Education', href: '#education' },
        { name: 'Outlook', href: '#outlook' },
        { name: 'Related Careers', href: '#related-careers' }
    ];

    // Create refs for each section
    const introductionRef = createRef<HTMLDivElement>();
    const dutiesRef = createRef<HTMLDivElement>();
    const skillsRef = createRef<HTMLDivElement>();
    const educationRef = createRef<HTMLDivElement>();
    const outlookRef = createRef<HTMLDivElement>();
    const relatedCareers = createRef<HTMLDivElement>();

    const [loading, setLoading] = useState(true);
    const [career, setCareer] = useState<Career>();
    const [careerSectionsRefs, setCareerSectionsRefs] = useState<RefObject<HTMLDivElement>[]>([]);

    useEffect(() => {
        const fetchCareer = async () => {
            try {
                setLoading(true);
                const response: Career = await CareersApi.read({id: careerSlug});
                setCareer(response);
                if (response.careerSections) {
                    setCareerSectionsRefs(response.careerSections.map(() => createRef<HTMLDivElement>()));
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchCareer();
    }, []);

    const handleNavClick = (href: string) => {
        const section = document.querySelector(href);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='career-details-home'>
            <Navbar />
            {career &&
              <>
                <CareerHeader careerName={career.title} navLinks={navLinks} onNavClick={handleNavClick} />
                <div className='career-details-section'>
                  <div className='sub-section' ref={introductionRef} id='introduction'>
                    <CareerIntroduction
                      description={career.description}
                      imageUrl={career.icon}
                      pronunciation={career.pronunciation}
                      pronunciationAudio={career.pronunciationAudio}
                      alternativeNames={career.alternativeNames}
                    />
                  </div>
                  <div className='sub-section' ref={dutiesRef} id='duties'>
                    <CareerDuties
                      dutiesDescription={career.duties}
                      dutiesInsight={career.dutiesInsight}
                      suggestedAssets={career.careerSuggestedAssets}/>
                  </div>
                    {career.relatedCareers && (
                         <div className='sub-section' ref={relatedCareers} id={'related-careers'}>
                            <RelatedCareerSection relatedCareers={career.relatedCareers}/>
                        </div>
                        )
                    }
                    {/* Additional components for career details can be added here */}
                </div>
              </>
            }
        </div>
    );
};

export default CareerDetailsPage;
