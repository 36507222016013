// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Career,
    CareerFromJSON,
    CareerToJSON,
    CareerCategory,
    CareerCategoryFromJSON,
    CareerCategoryToJSON,
    CareerListPaginated,
    CareerListPaginatedFromJSON,
    CareerListPaginatedToJSON,
    CareerSubcategory,
    CareerSubcategoryFromJSON,
    CareerSubcategoryToJSON,
} from '../models';

export interface CategoriesListRequest {
    title?: string;
}

export interface ListRequest {
    title?: string;
    subcategory?: number;
    page?: number;
    pageSize?: number;
}

export interface ReadRequest {
    id: string;
}

export interface SubcategoriesListRequest {
    category?: number;
}

/**
 * no description
 */
export class CareersApi extends runtime.BaseAPI {

    /**
     * Return a list of career categories, optionally filtered by title
     */
    async categoriesListRaw(requestParameters: CategoriesListRequest): Promise<runtime.ApiResponse<Array<CareerCategory>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/careers/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CareerCategoryFromJSON));
    }

    /**
     * Return a list of career categories, optionally filtered by title
     */
    async categoriesList(requestParameters: CategoriesListRequest): Promise<Array<CareerCategory>> {
        const response = await this.categoriesListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return a list of careers using CareerListSerializer with optional title and subcategory filter
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<CareerListPaginated>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.subcategory !== undefined) {
            queryParameters['subcategory'] = requestParameters.subcategory;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/careers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CareerListPaginatedFromJSON(jsonValue));
    }

    /**
     * Return a list of careers using CareerListSerializer with optional title and subcategory filter
     */
    async list(requestParameters: ListRequest): Promise<CareerListPaginated> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return career and its related content
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<Career>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/careers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CareerFromJSON(jsonValue));
    }

    /**
     * Return career and its related content
     */
    async read(requestParameters: ReadRequest): Promise<Career> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return a list of career subcategories filtered by category
     */
    async subcategoriesListRaw(requestParameters: SubcategoriesListRequest): Promise<runtime.ApiResponse<Array<CareerSubcategory>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/careers/subcategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CareerSubcategoryFromJSON));
    }

    /**
     * Return a list of career subcategories filtered by category
     */
    async subcategoriesList(requestParameters: SubcategoriesListRequest): Promise<Array<CareerSubcategory>> {
        const response = await this.subcategoriesListRaw(requestParameters);
        return await response.value();
    }

}
