import React from 'react';
import { WrappedMessage } from '../../../../utils';
import messages from '../../../displayMessages';

interface BasicsPillProps {
    title: string;
    info: string;
    description: string;
    icon: string; // Provide the path or name of the icon
    targetSectionId?: string; // ID of the section to scroll to
}

const BasicsPill: React.FC<BasicsPillProps> = ({title, info, description, icon, targetSectionId}) => {
    const handleReadMore = () => {
        if (targetSectionId) {
            const targetElement = document.getElementById(targetSectionId);
            if (targetElement) {
                targetElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    return (
        <div className='pill'>
            <div className='pill-content'>
                <div className='pill-info-title'>{title}</div>
                <div className='pill-info'>{info}</div>
                <div className='pill-info-description'>
                    {description.split(' ').slice(0, 7).join(' ')}
                    <span>
                        <button onClick={handleReadMore} className='read-more-button'>
                          <WrappedMessage message={messages.readMoreBasicSummary}/>
                        </button>
                    </span>
                </div>
            </div>
            <div className='pill-icon'>
                <img src={icon}/>
            </div>
        </div>
    );
};

export default BasicsPill;
