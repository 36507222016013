// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerCategory,
    CareerCategoryFromJSON,
    CareerCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerSubcategory
 */
export interface CareerSubcategory {
    /**
     * 
     * @type {number}
     * @memberof CareerSubcategory
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerSubcategory
     */
    subcategoryName: string;
    /**
     * Text definition of the career subcategory.
     * @type {string}
     * @memberof CareerSubcategory
     */
    definition?: string;
    /**
     * 
     * @type {CareerCategory}
     * @memberof CareerSubcategory
     */
    category: CareerCategory;
}

export function CareerSubcategoryFromJSON(json: any): CareerSubcategory {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subcategoryName': json['subcategory_name'],
        'definition': !exists(json, 'definition') ? undefined : json['definition'],
        'category': CareerCategoryFromJSON(json['category']),
    };
}

export function CareerSubcategoryToJSON(value?: CareerSubcategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'subcategory_name': value.subcategoryName,
        'definition': value.definition,
        'category': CareerCategoryToJSON(value.category),
    };
}


