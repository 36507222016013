import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-shortformat';
import { NavLink } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { RootState } from 'global/state';
import { LXThunkUnwrap } from 'global/types';
import { Notification, NotificationNtypeEnum } from 'labxchange-client';
import { Icon } from 'ui/components';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

import * as NotificationActions from '../../actions';
import { getNotificationList } from '../../selectors';

import { NotificationItem } from '../NotificationItem';
import { NotificationAlertItem } from '../NotificationAlertItem';

import messages from './displayMessages';
import { ROUTES } from 'global/constants';

interface StateProps {
    notifications?: ReadonlyArray<Notification>;
}

interface ActionProps {
    markAllRead: typeof NotificationActions.markAllNotificationsRead;
    markRead: typeof NotificationActions.markNotificationRead;
    markUnread: typeof NotificationActions.markNotificationUnread;
}

interface Props extends StateProps, LXThunkUnwrap<ActionProps> {
    hideDayLabels?: boolean;
}

interface NotificationDateMap {
    rangeMoment: moment.Moment;
    notificationsList: Notification[];
}

export class NotificationList extends React.PureComponent<Props> {

    public static defaultProps = {
        hideDayLabels: true,
    };

    renderNotification = (notification: Notification) => {
        const { markRead, markUnread } = this.props;
        if (notification.ntype === NotificationNtypeEnum.ProductInterview) {
            return (
            <NotificationAlertItem
                notification={notification}
                markRead={markRead}
                markUnread={markUnread}
            />
            );
        } else {
            return (
            <NotificationItem
                notification={notification}
                markRead={markRead}
                markUnread={markUnread}
            />
            );
        }
    }

    public render() {
        const { notifications } = this.props;
        const  notificationsPerRange: NotificationDateMap[] = [
            {rangeMoment: moment().subtract(1, 'day'), notificationsList: []},
            {rangeMoment: moment().subtract(2, 'day'), notificationsList: []},
            {rangeMoment: moment().subtract(7, 'day'), notificationsList: []},
            {rangeMoment: moment().subtract(1, 'year'), notificationsList: []},
        ];
        if (notifications && notifications.length) {
            notifications.forEach((notification) => {
                for (const { rangeMoment, notificationsList } of notificationsPerRange) {
                    if (notification.createdAt && notification.createdAt >= rangeMoment.toDate()) {
                        notificationsList.push(notification);
                        break;
                    }
                }
            });
        }
        return (
            <div className='notification-list'>
                <div className='notification-header'>
                    <div className='notification-title-actions-wrapper'>
                        <button
                         onClick={this.markAllRead}>
                            <WrappedMessage message={messages.markAllRead} />
                        </button>
                        <Icon name='primitive-dot' />
                        <NavLink to={`${ROUTES.Personal.PROFILE_SETTINGS}#notifications`}><WrappedMessage message={messages.settingsLink} /></NavLink>
                    </div>
                </div>

                {(notificationsPerRange).map(({rangeMoment, notificationsList}, i) => { return (
                    notificationsList.length?
                    <React.Fragment key={i}>
                        {this.props.hideDayLabels? null : <div className={'day-label'}>
                            {rangeMoment.add(1, 'day').calendar(undefined, {
                                sameDay: `[${intl.formatMessage(messages.todayNotificationsGroup)}]`,
                                lastDay: `[${intl.formatMessage(messages.yesterdayNotificationsGroup)}]`,
                                lastWeek: `[${intl.formatMessage(messages.lastWeekNotificationsGroup)}]`,
                                sameElse: `[${intl.formatMessage(messages.earlierNotificationsGroup)}]`,
                            })}
                        </div>}
                        <ul key={i} className='list-unstyled'>
                            {notificationsList.map((notification) => { return (
                                <li key={notification.id}>
                                    {this.renderNotification(notification)}
                                </li>
                            );})}
                        </ul>
                    </React.Fragment> : null);})}
                <div className='notification-footer'>
                    <Link to={ROUTES.Personal.NOTIFICATIONS}>
                        <WrappedMessage message={messages.seeAllNotifications} />
                    </Link>
                </div>
            </div>
        );
    }

    private markAllRead = (e: React.MouseEvent) => {
        e.stopPropagation();
        this.props.markAllRead();
    }
}

interface NotificationListContainerProps extends StateProps, LXThunkUnwrap<ActionProps> {
    hideDayLabels?: boolean;
}

class NotificationListContainerInternal extends React.PureComponent<NotificationListContainerProps> {
    public render() {
        return (
            <NotificationList
                notifications={this.props.notifications}
                markAllRead={this.props.markAllRead}
                markRead={this.props.markRead}
                markUnread={this.props.markUnread}
            />
        );
    }
}

// As in StandardPageLayout, using connect() as a decorator here makes the compiler fail.
export const NotificationListContainer = connect<StateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        notifications: getNotificationList(state, 7),
    }), {
        markAllRead: NotificationActions.markAllNotificationsRead,
        markRead: NotificationActions.markNotificationRead,
        markUnread: NotificationActions.markNotificationUnread,
    },
)(NotificationListContainerInternal);
