import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { CareerList, CareerListPaginated, CareerSubcategory } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import Breadcrumb from '../BreadCrumbs';
import Banner from '../Banner';
import Tabs from '../Tabs';
import SkillCard from '../CardView';
import CareerCard from './CareerCard';
import { Spinner } from 'ui/components';

export const CareersPage: React.FC = () => {
  const { field: subcategoryId } = useParams<{ field: string }>();
  const location = useLocation<{ subcategoryData: CareerSubcategory }>();
  const subcategoryData = location.state?.subcategoryData;

  const [loading, setLoading] = useState<boolean>(true);
  const [careers, setCareers] = useState<CareerList[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const fetchCareers = async (value?: string, page: number = 1) => {
    try {
      setLoading(true);
      const response: CareerListPaginated = await CareersApi.list({
        title: value, subcategory: subcategoryData?.id, page, pageSize: 16
      });
      setCareers(prevCareers => page === 1 ? response.results : [...prevCareers, ...response.results]);

      setHasNextPage(!!response.next);
      setCurrentPage(page);
    } catch (error) {
      setCareers([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching careers', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareers();
  }, [subcategoryId]);


  const breadcrumbLinks = [
    { label: 'Home', path: ROUTES.CareerExplorer.HOME },
    { label: subcategoryData?.category.categoryName, path: ROUTES.CareerExplorer.CAREER_SUBCATEGORY(Number(subcategoryData?.category.id)) },
    { label: subcategoryData?.subcategoryName },
  ];

  const skills = [
    { image: '/assets/images/career-explorer/career-card-placeholder.svg', title: 'LXC Lesson Name', description: 'Description about the LXC lesson.', link: '#' },
    { image: '/assets/images/career-explorer/career-card-placeholder.svg', title: 'LXC Lesson Name', description: 'Description about the LXC lesson.' , link: '#'},
    { image: '/assets/images/career-explorer/career-card-placeholder.svg', title: 'LXC Lesson Name', description: 'Description about the LXC lesson.' , link: '#'},
    { image: '/assets/images/career-explorer/career-card-placeholder.svg', title: 'LXC Lesson Name', description: 'Description about the LXC lesson.' , link: '#'},
  ];

  return (
      <div className='career-home'>
        {!loading ?
          <>
            <Breadcrumb links={breadcrumbLinks} />
            <Banner title={subcategoryData?.subcategoryName} description={subcategoryData?.definition || 'this is dummy sub category description'} />
            <Tabs tabs={[{ label: 'Careers' }, { label: 'Skills' }]} onTabChange={setActiveTab} />

            {activeTab === 0 &&
                <div className='careers-list'>
                  {careers.length ?
                      <>
                        {careers.map((career:CareerList, index:number) => (
                            <CareerCard
                                key={index}
                                // @ts-ignore
                                career={career}
                                isExpanded={expandedCardIndex === index}
                                onToggleExpand={() => setExpandedCardIndex(index)}
                            />
                        ))}
                      </>
                       :
                      <div>
                          No Career listed under {subcategoryData?.subcategoryName}
                      </div>
                  }
                </div>
            }
            <div className='skills-list'>
              {activeTab === 1 && skills.map((skill:any, index: number) => (
                  <SkillCard key={index} image={skill.image} title={skill.title} description={skill.description} link={skill.link} />
              ))}
            </div>
          </>
            :
            <Spinner />
        }

      </div>
  );
};

export default CareersPage;
