import * as React from 'react';
import { intl } from '../../../i18n';
import classNames from 'classnames';

import messages from '../../displayMessages';
import { Icon } from '../../../elements';
import { useLayoutSize } from 'utils';

interface BlockPreviewProps {
    previewImageUrl: string;
    button: React.ReactNode;
    isSimulationBlock?: boolean;
    showSafariWarning?: boolean;
}

export const BlockPreview: React.FunctionComponent<BlockPreviewProps> = (props) => {
    const layoutSize = useLayoutSize();
    const showMobileBanner = ['small', 'mobile', 'medium'].includes(layoutSize) &&
        props.isSimulationBlock;
    return (
        <div
            className='block-preview'
            style={{backgroundImage: `url(${props.previewImageUrl})`}}
        >
            {props.showSafariWarning && (
                <div className='simulation-banner'>
                    <span>
                        <Icon
                            className='user-experience-indicator'
                            name='device-desktop'
                            zoom='1.5em'
                        />
                    </span>
                    <span className='banner-text'>
                        {intl.formatMessage(messages.safariWarningBannerText)}
                    </span>
                </div>
            )}
            {showMobileBanner && (
                <div className='simulation-banner'>
                    <span>
                        <Icon
                            className='user-experience-indicator'
                            name='device-desktop'
                            zoom='1.5em'
                        />
                    </span>
                    <span className='banner-text'>
                    {intl.formatMessage(
                        messages.simulationMobileBannerInfo,
                        {
                            link: <a
                                href='https://labxchange.zendesk.com/hc/en-us/articles/360035703594-Technical-Requirements'
                                target='_blank'
                                rel='noreferrer'>
                                {intl.formatMessage(messages.simulationMobileBannerLearnMore)}</a>
                        }
                    )}
                </span>
                </div>
            )}
            <div className={classNames(
                'block-start-button-container',
                {'banner-visible': props.showSafariWarning || showMobileBanner })
            }>
                {props.button}
            </div>
            <div className='block-preview-overlay'></div>
        </div>
    );
};
