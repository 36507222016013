import * as React from 'react';

import bind from 'bind-decorator';
import messages from 'clusters/displayMessages';
import { isMouseOrEnterEvent } from 'global/utils';
import { ClusterItem, Tour } from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import { Icon } from 'ui/components';
import { WrappedMessage } from 'utils';
import { createAssetViewedEventForItem } from 'library/components/Block/utils';
import { Link } from 'react-router-dom';
import { intl } from 'i18n';

// This will only support labels for up to 20 items in a tour. If more are
// needed, then need to do something different.
const indexToWord = [
    intl.formatMessage(messages.clusterOrderOne),
    intl.formatMessage(messages.clusterOrderTwo),
    intl.formatMessage(messages.clusterOrderThree),
    intl.formatMessage(messages.clusterOrderFour),
    intl.formatMessage(messages.clusterOrderFive),
    intl.formatMessage(messages.clusterOrderSix),
    intl.formatMessage(messages.clusterOrderSeven),
    intl.formatMessage(messages.clusterOrderEight),
    intl.formatMessage(messages.clusterOrderNine),
    intl.formatMessage(messages.clusterOrderTen),
    intl.formatMessage(messages.clusterOrderEleven),
    intl.formatMessage(messages.clusterOrderTwelve),
    intl.formatMessage(messages.clusterOrderThirteen),
    intl.formatMessage(messages.clusterOrderFourteen),
    intl.formatMessage(messages.clusterOrderFifteen),
    intl.formatMessage(messages.clusterOrderSixteen),
    intl.formatMessage(messages.clusterOrderSeventeen),
    intl.formatMessage(messages.clusterOrderEighteen),
    intl.formatMessage(messages.clusterOrderNineteen),
    intl.formatMessage(messages.clusterOrderTwenty),
];

enum HexPerRow {
    Three,
    Four,
    Other,
}

interface Props {
    items: ClusterItem[];
    tours: Tour[];
    clusterMetadataId?: string;
}

interface State {
    showTours: boolean;
    hexPerRow: HexPerRow;
    highlightedTour: number|null;
}

export class ClusterGrid extends React.PureComponent<Props, State> {
    private mediaQueryThree = window.matchMedia('(min-width: 601px) and (max-width: 900px)');
    private mediaQueryFour = window.matchMedia('(min-width: 901px)');

    constructor(props: Props) {
        super(props);
        this.state = {
            showTours: false,
            highlightedTour: null,
            hexPerRow: HexPerRow.Four,
        };
    }

    public async componentDidMount() {
        this.mediaQueryThree.addListener(this.setViewSize);
        this.mediaQueryFour.addListener(this.setViewSize);
        this.setViewSize();
    }

    public render() {
        const { items, tours } = this.props;
        const toursElements = tours.map((tour, index) => {
            return (
                <button
                    key={index}
                    onClick={(e) => this.highlightTour(index, e)}
                    onKeyDown={(e) => this.highlightTour(index, e)}
                    className={`clusters ${this.state.highlightedTour === index ? 'active' : ''}`}>
                    {tour.title}
                </button>
            );
        });
        let highlightedTours: number[] = [];
        if (this.state.highlightedTour !== null) {
            highlightedTours = tours[this.state.highlightedTour].items;
        }
        const clusterButtons = items.map(({id, item, iconUrl}) => {
            const { metadata } = item; // item is ItemResponse, and also has userAttributes available
            const tourIndex = highlightedTours.indexOf(id);
            const itemLink = detailUrlForEntity(metadata) + `?source=${window.location.pathname}`;
            return (
                <div key={id} className='hex'>
                    <div className='hex-in'>
                        <Link
                            to={itemLink}
                            className={`btn unstyled hex-contents ${tourIndex !== -1 ? 'active' : ''}`}
                            onClick={() => this.onClusterItemClick()}
                            target='_parent'
                        >
                            {iconUrl &&
                                <img
                                    className='icon'
                                    alt=''
                                    src={iconUrl}
                                />
                            }
                            <p className='title'>
                                {tourIndex !== -1 && tourIndex < 20 && indexToWord[tourIndex]}
                            </p>
                            <p className='description'>{metadata.title}</p>
                        </Link>
                    </div>
                </div>
            );
        });

        const insertSpacers = (...indices: number[]) => {
            for (const i of indices) {
                clusterButtons.splice(i, 0, (
                    <div className='hex'>
                        <div className='hex-in'></div>
                    </div>
                ));
            }
        };

        // This is where we insert spacer hex items depending on the number of
        // cluster items and viewport width. These spacers are to retain the
        // desired layout symmetry.
        const n = items.length;
        switch (this.state.hexPerRow) {
            case HexPerRow.Three: {
                switch (n) {
                    case 3: {
                        insertSpacers(2);
                        break;
                    }
                    case 8: {
                        insertSpacers(7);
                        break;
                    }
                    case 13: {
                        insertSpacers(12);
                        break;
                    }
                }
                break;
            }
            case HexPerRow.Four: {
                switch (n) {
                    case 4:
                    case 5: {
                        insertSpacers(3);
                        break;
                    }
                    case 8: {
                        insertSpacers(3, 6);
                        break;
                    }
                    case 11:
                    case 12: {
                        insertSpacers(10);
                        break;
                    }
                    case 15: {
                        insertSpacers(10, 13);
                        break;
                    }
                }
                break;
            }
        }
        return (
            <>
                {tours.length > 0 &&
                    <div className={`cluster-collapse ${this.state.showTours ? 'cluster-collapse-open' : ''}`}>
                        <button className='unstyled' onClick={this.toggleTours} onKeyDown={this.toggleTours}>
                            <h2 className='collapsible'>
                                <WrappedMessage message={messages.toursHeader} />
                                <Icon name='chevron-down' zoom='30' />
                            </h2>
                        </button>
                        <div className='cluster-collapse-region'>
                            {toursElements}
                        </div>
                    </div>
                }
                <div className='cluster-body'>
                    {clusterButtons}
                </div>
            </>
        );
    }

    private async onClusterItemClick() {
        if (this.props.clusterMetadataId) {
            await createAssetViewedEventForItem(this.props.clusterMetadataId, false);
        }
    }

    @bind private setViewSize(): void {
        let hexPerRow: HexPerRow;
        if (this.mediaQueryThree.matches) {
            hexPerRow = HexPerRow.Three;
        } else if (this.mediaQueryFour.matches) {
            hexPerRow = HexPerRow.Four;
        } else {
            hexPerRow = HexPerRow.Other;
        }
        this.setState({ hexPerRow });
    }

    @bind private toggleTours(event: React.MouseEvent<any>|React.KeyboardEvent<any>) {
        if (isMouseOrEnterEvent(event)) {
            event.preventDefault();
            this.setState({ showTours: !this.state.showTours });
        }
    }

    @bind private highlightTour(index: number, event: React.MouseEvent<any>|React.KeyboardEvent<any>) {
        if (isMouseOrEnterEvent(event)) {
            event.preventDefault();
            // If the clicked button is for the tour already highlighted, then
            // toggle it off. Otherwise, highlight it.
            if (this.state.highlightedTour === index) {
                this.setState({ highlightedTour: null });
            } else {
                this.setState({ highlightedTour: index });
            }
        }
    }
}
