import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitleCreateItemPage: {
        id:'pageTitleCreateItemPage',
        defaultMessage: 'Create {assetType}',
        description: 'HTML title for the item create page.',
    },
    pageTitleEditItemPage: {
        id:'pageTitleEditItemPage',
        defaultMessage: 'Edit {name}',
        description: 'HTML title for the item edit page.',
    },
    ariaTitleInputLabel: {
        id:'ariaTitleInputLabel',
        defaultMessage: '{itemType} title input',
        description: 'Aria label for item title input.',
    },
    titleInputPlaceholder: {
        id:'titleInputPlaceholder',
        defaultMessage: 'Enter a title',
        description: 'Item title input placeholder.',
    },
    descriptionTextAreaLabel: {
        id:'descriptionTextAreaLabel',
        defaultMessage: '{itemType} description',
        description: 'Item description text area label.',
    },
    descriptionTextAreaPlaceholder: {
        id:'descriptionTextAreaPlaceholder',
        defaultMessage: 'Enter a description for your {itemType}',
        description: 'Item description text area placeholder.',
    },
    itemTagsSectionTitle: {
        id:'itemTagsSectionTitle',
        defaultMessage: 'Tags',
        description: 'Item tags section title.',
    },
    addTagButton: {
        id:'addTagButton',
        defaultMessage: 'Add',
        description: 'Add tag button.',
    },
    addTagInputPlaceholder: {
        id:'addTagInputPlaceholder',
        defaultMessage: 'Enter tag',
        description: 'Add tag input placeholder.',
    },
    addTagLabel: {
        id:'addTagLabel',
        defaultMessage: 'Add tags to help users to find your content. Separate the tags with commas.',
        description: 'Add tag label.',
    },
    ariaRemoveTagButtonLabel: {
        id:'ariaRemoveTagButtonLabel',
        defaultMessage: 'Remove "{tagName}" tag.',
        description: 'Aria label for tag removing button.',
    },
    backToEdit: {
        id:'backToEdit',
        defaultMessage: 'Back to edit',
        description: 'Message for incomplete modal window',
    },
    goToOverview: {
        id: 'goToOverview',
        defaultMessage: 'Go to overview',
        description: 'Message for incomplete modal window'
    },
    publishAsPrivate: {
        id:'publishAsPrivate',
        defaultMessage: 'Publish as private',
        description: 'Message for publishing private incomplete item',
    },
    saveAnyway: {
        id: 'saveAnyway',
        defaultMessage: 'Save anyway',
        description: 'Message for publishing after warning'
    },
    savePrivately: {
        id: 'savePrivately',
        defaultMessage: 'Save privately',
        description: 'Message for publishing private incomplete item'
    },
    deleteItemButton: {
        id:'deleteItemButton',
        defaultMessage: 'Delete {itemType}',
        description: 'Delete item button.',
    },
    saveDraftButton: {
        id:'saveDraftButton',
        defaultMessage: 'Save draft',
        description: 'Save draft button.',
    },
    publishPubliclyButton: {
        id:'publishPubliclyButton',
        defaultMessage: 'Publish',
        description: 'Publish publicly button.',
    },
    publishPrivatelyButton: {
        id:'publishPrivatelyButton',
        defaultMessage: 'Save {itemType}',
        description: 'Publish publicly button.',
    },
    saveButton: {
        id:'saveButton',
        defaultMessage: 'Save {itemType}',
        description: 'Save publicly button.',
    },
    publishPubliclySavingButton: {
        id:'publishPubliclySavingButton',
        defaultMessage: 'Publishing...',
        description: 'Publish button changes to this text when the item is being saved',
    },
    publishAndAddToPathwayButton: {
        id:'publishAndAddToPathwayButton',
        defaultMessage: 'Add to private library and pathway',
        description: 'Publish button when adding through "add new content" from pathway',
    },
    publishAndAddToAssignmentButton: {
        id:'publishAndAddToAssignmentButton',
        defaultMessage: 'Add to private library and question set',
        description: 'Publish button when adding through "add new content" from pathway',
    },
    publishItemButton: {
        id:'publishItemButton',
        defaultMessage: 'Publish',
        description: 'Publish button when adding through "add new content" from item',
    },
    saveItemButton: {
        id:'saveItemButton',
        defaultMessage: 'Save {itemType}',
        description: 'Save button when adding through "add new content" from item',
    },
    ariaOriginalAuthorInputLabel: {
        id:'ariaOriginalAuthorInputLabel',
        defaultMessage: 'Original author input.',
        description: 'Aria label for item original author input.',
    },
    originalAuthorInputPlaceholder: {
        id:'originalAuthorInputPlaceholder',
        defaultMessage: 'Add original author',
        description: 'Add original author input placeholder.',
    },
    defaultSelectValue: {
        id:'defaultSelectValue',
        defaultMessage: 'Select',
        description: 'Default select value.',
    },
    loginRequiredToEdit: {
        id:'loginRequiredToEdit',
        defaultMessage: 'You must log in to edit content.',
        description: 'Message shown if a user tries to edit content without being logged in.',
    },
    narrativeEditorDescriptionField: {
        id:'narrativeEditorDescriptionField',
        defaultMessage: `A narrative is a personal story about your journey in science.
         Here, we recommend you include a brief biography.`,
        description: 'Label for the description field for the narrative',
    },
    backToPathway: {
        id:'backToPathway',
        defaultMessage: 'Back to pathway',
        description: 'Label for the button to return to pathway after getting here via "add new content".',
    },
    backToAssignment: {
        id:'backToAssignment',
        defaultMessage: 'Back to question set',
        description: 'Label for the button to return to assignment after getting here via "add new content".',
    },
    backToCaseStudy: {
        id:'backToCaseStudy',
        defaultMessage: 'Back to case study',
        description: 'Label for the button to return to case study after getting here via "add new content".',
    },
    backToTeachingGuide: {
        id:'backToTeachingGuide',
        defaultMessage: 'Back to teaching guide',
        description: 'Label for the button to return to teaching guide after getting here via "add new content".',
    },
    failedToAddItem: {
        id:'failedToAddItemToPathway',
        defaultMessage: 'Failed to add item to pathway',
        description: 'Message when fails to add to pathway on item save.',
    },
    failedToAddItemToAssignment: {
        id:'failedToAddItemToAssignment',
        defaultMessage: 'Failed to add item to question set',
        description: 'Message when fails to add to assignment on item save.',
    },
    failedToAddItemToCaseStudy: {
        id:'failedToAddItemToCaseStudy',
        defaultMessage: 'Failed to add item to case study',
        description: 'Message when fails to add to case study on item save.',
    },
    failedToAddItemToTeachingGuide: {
        id:'failedToAddItemToTeachingGuide',
        defaultMessage: 'Failed to add item to teaching guide',
        description: 'Message when fails to add to teaching guide on item save.',
    },
    saveAssignmentQuestion: {
        id:'saveAssignmentQuestion',
        defaultMessage: 'Save question set?',
        description: 'Title of confirmation modal to save and leave the assignment editor.',
    },
    saveAssignmentExplanation: {
        id:'saveAssignmentExplanation',
        defaultMessage: 'You are about to leave the question set builder. All your work will be saved, and the question will be added once you click save.',
        description: 'Text of confirmation modal to save and leave the assignment editor.',
    },
    saveCaseStudyQuestion: {
        id:'saveCaseStudyQuestion',
        defaultMessage: 'Save case study?',
        description: 'Title of confirmation modal to save and leave the case study editor.',
    },
    saveCaseStudyExplanation: {
        id:'saveCaseStudyExplanation',
        defaultMessage: 'You are about to leave the case study builder. All your work will be saved, and the asset will be added once you click save.',
        description: 'Text of confirmation modal to save and leave the case study editor.',
    },
    saveTeachingGuideQuestion: {
        id:'saveTeachingGuideQuestion',
        defaultMessage: 'Save teaching guide?',
        description: 'Title of confirmation modal to save and leave the teaching guide editor.',
    },
    saveTeachingGuideExplanation: {
        id:'saveTeachingGuideExplanation',
        defaultMessage: 'You are about to leave the teaching guide builder. All your work will be saved, and the asset will be added once you click save.',
        description: 'Text of confirmation modal to save and leave the teaching guide editor.',
    },
    newAssignment: {
        id:'newAssignment',
        defaultMessage: 'New Question Set',
        description: 'Default title of new autosaved assignment.',
    },
    missingRequiredFieldsTitle: {
        id:'missingRequiredFieldsTitle',
        defaultMessage: 'Incomplete',
        description: 'Pre publish alert title',
    },
    missingRequiredFields: {
        id:'missingRequiredFields',
        defaultMessage: 'Please complete the following fields in order to publish your {itemType}:',
        description: 'Pre publish alert message',
    },
    missingBackgroundKnowledge: {
        id:'missingBackgroundKnowledge',
        defaultMessage: 'Background knowledge',
        description: 'List item for missing form field',
    },
    missingFeaturedImage: {
        id:'missingFeaturedImage',
        defaultMessage: 'Featured Image',
        description: 'List item for missing form field',
    },
    missingSubject: {
        id:'missingSubject',
        defaultMessage: 'Subject',
        description: 'List item for missing form field',
    },
    missingTitle: {
        id:'missingTitle',
        defaultMessage: 'Title',
        description: 'List item for missing form field',
    },
    missingTags: {
        id:'missingTags',
        defaultMessage: 'Tags',
        description: 'List item for missing form field',
    },
    missingTOS: {
        id:'missingTOS',
        defaultMessage: 'Agree to the terms of service and privacy notice',
        description: 'User has not agreed with terms of service and privacy policy',
    },
    assignmentRequiredFields: {
        id:'assignmentRequiredFields',
        defaultMessage: '2 questions required',
        description: 'Required item type specific fields',
    },
    imageRequiredFields: {
        id:'imageRequiredFields',
        defaultMessage: 'Image, alt text and citation are required',
        description: 'Required item type specific fields',
    },
    htmlRequiredFields: {
        id:'htmlRequiredFields',
        defaultMessage: 'Data required',
        description: 'Required item type specific fields',
    },
    documentRequiredFields: {
        id:'documentRequiredFields',
        defaultMessage: 'A document is required',
        description: 'Required item type specific fields',
    },
    googleDocumentRequiredFields: {
        id:'googleDocumentRequiredFields',
        defaultMessage: 'A link to google doc link is required',
        description: 'Required item type specific fields',
    },
    narrativeRequiredFields: {
        id:'narrativeRequiredFields',
        defaultMessage: 'All contents are required',
        description: 'Required item type specific fields',
    },
    problemRequiredFields: {
        id:'problemRequiredFields',
        defaultMessage: 'Question required fields are not complete',
        description: 'Required item type specific fields',
    },
    videoRequiredFields: {
        id:'videoRequiredFields',
        defaultMessage: 'A video ID is required',
        description: 'Required item type specific fields',
    },
    videoTranscriptsRequiredField: {
        id: 'videoTranscriptsRequiredField',
        defaultMessage: 'A transcript is required',
        describe: 'Alert for required trasncript on videos',
    },
    audioRequiredFields: {
        id:'audioRequiredFields',
        defaultMessage: 'Embeded code required',
        description: 'Required item type specific fields',
    },
    unknownXBlockType: {
        id:'unknownXBlockType',
        defaultMessage: 'Unknown xblock type',
        description: 'Message if xblock type does not match expected',
    },
    newCaseStudy: {
        id:'newCaseStudy',
        defaultMessage: 'New Case Study',
        description: 'Default title of new autosaved case study.',
    },
    newTeachingGuide: {
        id:'newTeachingGuide',
        defaultMessage: 'New Teaching Guide',
        description: 'Default title of new autosaved teaching guide.',
    },
    languageChanged: {
        id: 'languageChanged',
        defaultMessage: 'The original language ({originalLanguage}) of this asset is not supported on LabXchange. The language of this asset will be saved as English by default.',
        description: 'Warning message shown when an imported asset was detected as an unsupported language and the metadata language has been reset to the default language.',
    },
    notLoginEditAssetAlertTitle: {
        id: 'notLoginEditAssetAlertTitle',
        defaultMessage: 'You can’t edit content that isn’t yours',
        description: 'Title of the alert page when the user tries to edit an asset without being logged in',
    },
    notLoginEditAssetAlertBody: {
        id: 'notLoginEditAssetAlertBody',
        defaultMessage: 'You are not the owner of the content, so you cannot edit or update it.',
        description: 'Body of the alert page when the user tries to edit an asset without being logged in',
    },
    saveWithoutTranscriptAlertTitle: {
        id: 'saveWithoutTranscriptAlertTitle',
        defaultMessage: 'Save without a transcript?',
        description: 'Title of the alert when the user tries to save a video without transcript',
    },
    saveWithoutTranscriptAlertBody: {
        id: 'saveWithoutTranscriptAlertBody',
        defaultMessage: 'This video is currently not accessible for all learners.',
        description: 'Body of the alert when the user tries to save a video without transcript',
    },
    noTranscriptAlertTitle: {
        id: 'noTranscriptAlertTitle',
        defaultMessage: 'No transcript.',
        description: 'Title of the alert when the user tries to save an annotated video without transcript',
    },
    noTranscriptAlertBody: {
        id: 'noTranscriptAlertBody',
        defaultMessage: 'You haven’t added a transcript to this annotated video. You can either save privately and add the transcript later, or return to the edit screen.',
        description: 'Body of the alert when the user tries to save an annotated video without transcript',
    },
    someFieldsAreMissingAlertTitle: {
        id: 'someFieldsAreMissingAlertTitle',
        defaultMessage: 'Some fields are missing',
        description: 'Title of the alert when the user tries to save an annotatde video with missing fields',
    },
    someFieldsAreMissingAlertBody: {
        id: 'someFieldsAreMissingAlertBody',
        defaultMessage: 'Some fields are missing from this annotated video. Go to the overview page and make sure everything is completed. ',
        description: 'Body of the alert when the user tries to save an annotatde video with missing fields',
    },
});

export default messages;
