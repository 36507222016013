import React, { useState } from 'react';

interface CareerHeaderProps {
    careerName: string;
    navLinks: { name: string; href: string }[];
    onNavClick: (href: string) => void;
}

export const CareerHeader: React.FC<CareerHeaderProps> = ({ careerName, navLinks, onNavClick }) => {
    const [selectedLink, setSelectedLink] = useState<number>(0);

    const handleClick = (href: string, index: number) => {
        setSelectedLink(index);
        onNavClick(href);
    };
    return (
        <header className='career-header'>
            <h1 className='career-header__title'>{careerName}</h1>
            <nav className='career-header__nav'>
                <ul className='career-header__nav-list'>
                    {navLinks.map((link, index) => (
                        <li key={index} className='career-header__nav-item'>
                            <a
                                href={link.href}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClick(link.href, index);
                                }}
                                className={`career-header__nav-link ${selectedLink === index ? 'career-header__nav-link-active' : ''}`}
                            >
                                {link.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
};

export default CareerHeader;
