import React from 'react';
import { Icon } from '../../../../elements';
import { WrappedMessage } from '../../../../utils';
import messages from '../../../displayMessages';
import CareerTasks from './CareerTasks';
import { CareerSuggestedAssets } from 'labxchange-client';

interface CareerDutiesProps {
    dutiesDescription: string;
    dutiesInsight: string;
    suggestedAssets?: CareerSuggestedAssets[];
}

const CareerDuties: React.FC<CareerDutiesProps> = ({
  dutiesDescription,
  dutiesInsight,
  suggestedAssets,
}) => {

    return (
       <div className='career-duties'>
           <h2> <WrappedMessage message={messages.CareerDutiesHeadingText} /> </h2>
           <div className='career-duties-description'>
               {dutiesDescription}
           </div>
           <div className='divider' data-testid='divider'></div>
           <div className='career-duties-insight'>
               <Icon name='info' zoom='1em' fill={'#00AEEF'} />
               <div className='insight'>
                   {dutiesInsight}
               </div>
           </div>
           {suggestedAssets &&
             <CareerTasks suggestedAssets={suggestedAssets} />
           }
       </div>
    );
};

export default CareerDuties;
