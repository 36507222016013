// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Author,
    AuthorFromJSON,
    AuthorToJSON,
    LearningObjective,
    LearningObjectiveFromJSON,
    LearningObjectiveToJSON,
    PartialBook,
    PartialBookFromJSON,
    PartialBookToJSON,
    ResourceRelationship,
    ResourceRelationshipFromJSON,
    ResourceRelationshipToJSON,
} from './';

/**
 * 
 * @export
 * @interface ItemMetadataUpdate
 */
export interface ItemMetadataUpdate {
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    readonly id2?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    relId?: string;
    /**
     * 
     * @type {Array<Author>}
     * @memberof ItemMetadataUpdate
     */
    authors?: Array<Author>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    backgroundKnowledge?: ItemMetadataUpdateBackgroundKnowledgeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    references?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemMetadataUpdate
     */
    freeFormTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    language?: string;
    /**
     * 
     * @type {Array<LearningObjective>}
     * @memberof ItemMetadataUpdate
     */
    learningObjectives?: Array<LearningObjective>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    ownerIndividual?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemMetadataUpdate
     */
    subjectTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    type?: ItemMetadataUpdateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    license?: ItemMetadataUpdateLicenseEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadataUpdate
     */
    isPublic?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadataUpdate
     */
    uploadedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadataUpdate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadataUpdate
     */
    publishedAt?: Date;
    /**
     * 
     * @type {PartialBook}
     * @memberof ItemMetadataUpdate
     */
    book?: PartialBook;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    translationOf?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadataUpdate
     */
    sendPiiMetadata?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadataUpdate
     */
    toBeUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadataUpdate
     */
    sourceUrl?: string;
    /**
     * 
     * @type {Array<ResourceRelationship>}
     * @memberof ItemMetadataUpdate
     */
    resources?: Array<ResourceRelationship>;
    /**
     * 
     * @type {number}
     * @memberof ItemMetadataUpdate
     */
    sourceId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadataUpdate
     */
    isBlockChange?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadataUpdate
     */
    notifyUpdate?: boolean;
}

export function ItemMetadataUpdateFromJSON(json: any): ItemMetadataUpdate {
    return {
        'id2': !exists(json, 'id2') ? undefined : json['id2'],
        'relId': !exists(json, 'rel_id') ? undefined : json['rel_id'],
        'authors': !exists(json, 'authors') ? undefined : (json['authors'] as Array<any>).map(AuthorFromJSON),
        'backgroundKnowledge': !exists(json, 'background_knowledge') ? undefined : json['background_knowledge'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'freeFormTags': !exists(json, 'free_form_tags') ? undefined : json['free_form_tags'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'learningObjectives': !exists(json, 'learning_objectives') ? undefined : (json['learning_objectives'] as Array<any>).map(LearningObjectiveFromJSON),
        'ownerIndividual': !exists(json, 'owner_individual') ? undefined : json['owner_individual'],
        'subjectTags': !exists(json, 'subject_tags') ? undefined : json['subject_tags'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'license': !exists(json, 'license') ? undefined : json['license'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'uploadedDate': !exists(json, 'uploaded_date') ? undefined : new Date(json['uploaded_date']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'publishedAt': !exists(json, 'published_at') ? undefined : new Date(json['published_at']),
        'book': !exists(json, 'book') ? undefined : PartialBookFromJSON(json['book']),
        'translationOf': !exists(json, 'translation_of') ? undefined : json['translation_of'],
        'sendPiiMetadata': !exists(json, 'send_pii_metadata') ? undefined : json['send_pii_metadata'],
        'toBeUpdated': !exists(json, 'to_be_updated') ? undefined : new Date(json['to_be_updated']),
        'sourceUrl': !exists(json, 'source_url') ? undefined : json['source_url'],
        'resources': !exists(json, 'resources') ? undefined : (json['resources'] as Array<any>).map(ResourceRelationshipFromJSON),
        'sourceId': !exists(json, 'source_id') ? undefined : json['source_id'],
        'isBlockChange': !exists(json, 'is_block_change') ? undefined : json['is_block_change'],
        'notifyUpdate': !exists(json, 'notify_update') ? undefined : json['notify_update'],
    };
}

export function ItemMetadataUpdateToJSON(value?: ItemMetadataUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'rel_id': value.relId,
        'authors': value.authors === undefined ? undefined : (value.authors as Array<any>).map(AuthorToJSON),
        'background_knowledge': value.backgroundKnowledge,
        'description': value.description,
        'references': value.references,
        'free_form_tags': value.freeFormTags,
        'image_url': value.imageUrl,
        'language': value.language,
        'learning_objectives': value.learningObjectives === undefined ? undefined : (value.learningObjectives as Array<any>).map(LearningObjectiveToJSON),
        'owner_individual': value.ownerIndividual,
        'subject_tags': value.subjectTags,
        'title': value.title,
        'type': value.type,
        'license': value.license,
        'is_public': value.isPublic,
        'uploaded_date': value.uploadedDate === undefined ? undefined : value.uploadedDate.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'published_at': value.publishedAt === undefined ? undefined : value.publishedAt.toISOString(),
        'book': PartialBookToJSON(value.book),
        'translation_of': value.translationOf,
        'send_pii_metadata': value.sendPiiMetadata,
        'to_be_updated': value.toBeUpdated === undefined ? undefined : value.toBeUpdated.toISOString(),
        'source_url': value.sourceUrl,
        'resources': value.resources === undefined ? undefined : (value.resources as Array<any>).map(ResourceRelationshipToJSON),
        'source_id': value.sourceId,
        'is_block_change': value.isBlockChange,
        'notify_update': value.notifyUpdate,
    };
}

/**
* @export
* @enum {string}
*/
export enum ItemMetadataUpdateBackgroundKnowledgeEnum {
    None = 'none',
    Some = 'some',
    Extensive = 'extensive',
    Empty = ''
}
/**
* @export
* @enum {string}
*/
export enum ItemMetadataUpdateTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}
/**
* @export
* @enum {string}
*/
export enum ItemMetadataUpdateLicenseEnum {
    LX1 = 'LX1',
    PD = 'PD',
    CCBY40 = 'CC BY 4.0',
    CCBYNC40 = 'CC BY-NC 4.0',
    CCBY30 = 'CC BY 3.0',
    CCBYNC30 = 'CC BY-NC 3.0',
    CCBY25 = 'CC BY 2.5',
    CCBYNC25 = 'CC BY-NC 2.5',
    CCBY20 = 'CC BY 2.0',
    CCBYNC20 = 'CC BY-NC 2.0',
    CCBY10 = 'CC BY 1.0',
    CCBYNC10 = 'CC BY-NC 1.0'
}


