import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { CareerCategory } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { Spinner } from 'ui/components';

import messages from '../../displayMessages';
import { isKeyboardEnterEvent } from '../../../global/utils';

export const CategoryHome: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get('title');
  const internalUser = queryParams.get('isInternal');
  const history = useHistory();

  if(!internalUser){
    history.push(ROUTES.Explore.HOME);
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<CareerCategory[]>([]);

  const fetchCategories = async (value?: string) => {
    try {
      setLoading(true);
      const response = await CareersApi.categoriesList({title: value});
      setCategories(response);
    } catch (error) {
      setCategories([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching categories', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories(query || '');
  }, [query]);

  const onClickCard = (selectedCategory: CareerCategory) => {
    history.push({
      pathname: ROUTES.CareerExplorer.CAREER_SUBCATEGORY(Number(selectedCategory.id)),
      state: { categoryData: selectedCategory }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
      <div className='career-home'>
      <span className='results-count'>
        {intl.formatMessage(messages.careerResultsText, {
          count: categories.length,
          plural: categories.length === 1 ? '' : 's',
        })}
      </span>
        {!categories.length && query && !loading ? (
            <div className='empty-state'>
              <img
                  className='empty-state-image'
                  alt='No results found'
                  src='/assets/images/category-explorer/category-empty-state.svg'
              />
              <h5>{intl.formatMessage(messages.careerNoResultsHeading)}</h5>
              <span>{intl.formatMessage(messages.careerNoResultsSubheading)}</span>
            </div>
        ) : (
            <div className='careers-list'>
              {categories.map((category) => (
                  <div
                      tabIndex={0}
                      role='button'
                      className={classNames('career-card')}
                      onClick={() => onClickCard(category)}
                      onKeyDown={(e) => isKeyboardEnterEvent(e) && onClickCard(category)}
                  >
                    <img
                        className='career-icon'
                        alt='Career Icon'
                        src='/assets/images/career-explorer/career-card-placeholder.svg'
                    />
                    <div className='career-info'>
                      <span className='ce-heading-xs'>{category.categoryName}</span>
                      <span className='ce-label-small coming-soon-text'>
                        {category.definition || intl.formatMessage(messages.careerCategoryCardDummyDescription)}
                      </span>
                    </div>
                  </div>
              ))}
            </div>
        )}
      </div>
  );
};

export default CategoryHome;
