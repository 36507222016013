import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {CareerCategory, CareerSubcategory} from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import messages from '../../displayMessages';
import { isKeyboardEnterEvent} from '../../../global/utils';
import Breadcrumb from '../BreadCrumbs';
import Banner from '../Banner';
import Tabs from '../Tabs';
import SkillCard from '../CardView';
import { Spinner } from 'ui/components';

export const SubCategoryHome: React.FC = () => {
  const { category: categoryId } = useParams<{ category: string }>();
  const location = useLocation<{ categoryData: CareerCategory }>();
  const categoryData = location.state?.categoryData;

  const [loading, setLoading] = useState<boolean>(true);
  const [subCategories, subSubCategories] = useState<CareerSubcategory[]>([]);
  const [categoryInfo, setCategoryInfo] = useState<CareerCategory>(categoryData);
  const [activeTab, setActiveTab] = useState(0);

  const history = useHistory();

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await CareersApi.subcategoriesList({ category: Number(categoryId) });
      if(response.length){
        subSubCategories(response);
        setCategoryInfo(response[0].category);
      }
    } catch (error) {
      subSubCategories([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching subCategories', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [categoryId]);

  const onClickCard = (subcategory: CareerSubcategory) => {
    history.push({
      pathname: ROUTES.CareerExplorer.CAREER_LIST(Number(categoryId), Number(subcategory.id)),
      state: { categoryData, subcategoryData: subcategory },
    });
  };

  const breadcrumbLinks = [
    { label: 'Home', path: ROUTES.CareerExplorer.HOME },
    { label: categoryInfo?.categoryName },
  ];

  const skills: any = [];

  if (loading) {
    return <Spinner />; // Replace with your actual loading structure
  }

  return (
      <>
        {!loading &&
            <div className='career-home'>
              <Breadcrumb links={breadcrumbLinks} />
              <Banner
                  title={categoryInfo.categoryName}
                  description={categoryInfo.definition}
              />
              <Tabs tabs={[{ label: 'Fields' }, { label: 'Skills' }]} onTabChange={setActiveTab} />

              <div className='careers-list'>
                {activeTab === 0 && (subCategories.length > 0 ? (
                    subCategories.map((category) => (
                        <div
                            key={category.id}
                            tabIndex={0}
                            role='button'
                            className={classNames('career-card')}
                            onClick={() => onClickCard(category)}
                            onKeyDown={(e) => isKeyboardEnterEvent(e) && onClickCard(category)}
                        >
                          <img
                              className='career-icon'
                              alt='Career Icon'
                              src='/assets/images/career-explorer/career-card-placeholder.svg'
                          />
                          <div className='career-info'>
                            <span className='ce-heading-xs'>{category.subcategoryName}</span>
                            <span className='ce-label-small coming-soon-text'>
                              {category.definition || intl.formatMessage(messages.careerCategoryCardDummyDescription)}
                            </span>
                          </div>
                        </div>
                    ))
                ) : (
                    <div className='empty-state'>
                      <p>No fields listed under {categoryInfo.categoryName}</p>
                    </div>
                ))}
              </div>

              <div className='skills-list'>
                {activeTab === 1 && (skills.length > 0 ? (
                    skills.map((skill: any, index: number) => (
                        <SkillCard
                            key={index}
                            image={skill.image}
                            title={skill.title}
                            description={skill.description}
                            link={skill.link}
                        />
                    ))
                ) : (
                    <div className='empty-state'>
                      <img
                          className='empty-state-image'
                          alt='No results found'
                          src='/assets/images/career-explorer/career-empty-state.svg'
                      />
                      <div className='no-skills'>No skills listed under {categoryInfo.categoryName} </div>
                    </div>
                ))}
              </div>
            </div>
        }
      </>
  );
};

export default SubCategoryHome;
