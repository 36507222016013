import React from 'react';
import { Icon } from '../../../../elements';
import { CareerSuggestedAssets } from '../../../../labxchange-client';
import { NavLink } from 'react-router-dom';
import { detailUrlForEntity } from '../../../../library/utils';
import { WrappedMessage } from '../../../../utils';
import messages from '../../../displayMessages';
import { intl } from '../../../../i18n';

// Dummy Data
export const tasks = [
    'Collect biological specimens',
    'Prepare biological samples for testing',
    'Document research findings',
    'Monitor laboratory equipment',
    'Keep detailed logs of work activities',
];

// TaskItem Component
export const TaskItem = ({ taskNumber, text }: { taskNumber: number, text: string }) => {
    return (
        <div className='task-item'>
            <div className='task-item__number'>#{taskNumber}</div>
            <div className='task-item__text'>{text}</div>
            <div data-testid='icon-chevron-right-thin'>
                <Icon name='chevron-right-thin' fill={'#00AEEF'} zoom='1.5em'/>
            </div>

        </div>
    );
};

export const ChallengeCard = ({ suggestedAsset }: { suggestedAsset: CareerSuggestedAssets }) => {

    const suggestedAssetInfo = suggestedAsset.items?.[0].metadata;
    const labXLogo = '/assets/images/career-explorer/labxchange-asset-logo.svg';
    const defaultAssetImage = '/assets/images/career-explorer/challenge-card.svg';

    return (
        <>
            {suggestedAssetInfo && (
                <div className='challenge-card'>
                    <img
                        data-testid='assetImage'
                        alt={intl.formatMessage(messages.altTextForImages)}
                        src={suggestedAssetInfo.imageUrl || defaultAssetImage}
                    />
                    <div className='challenge-card__content'>
                        <h3>{suggestedAssetInfo.title}</h3>
                        <p>{suggestedAssetInfo.description}</p>
                        <NavLink
                            to={detailUrlForEntity(suggestedAssetInfo)}
                            className='challenge-card__button'
                        >
                            <div>
                                <img data-testid='labXlogo' alt={intl.formatMessage(messages.altTextForImages)} src={labXLogo} className='logo'/>
                                <WrappedMessage message={messages.careerChallengeCardCTAText}/>
                            </div>

                            <div data-testid='openExternalIcon'>
                                <Icon name='link-external' fill='#00AEEF' zoom='1em'></Icon>
                            </div>
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
};

interface CareerTaskProps {
    suggestedAssets: CareerSuggestedAssets[];
}

const CareerTasks: React.FC<CareerTaskProps> = ({suggestedAssets}) => {
    return (
        <div className='career-tasks'>
            <div>
                <h2><WrappedMessage message={messages.careerTasksMainHeading}/></h2>
                <p><WrappedMessage message={messages.careerTasksDescription} /></p>

                <div className='tasks-section'>
                    <img
                        alt={intl.formatMessage(messages.altTextForImages)}
                        src={'/assets/images/career-explorer/task-background.svg'}
                    />
                    <div className='task-list'>
                        {tasks.map((task, index) => (
                            <TaskItem key={index} taskNumber={index + 1} text={task} />
                        ))}
                    </div>
                </div>
            </div>

            <div>
                <h3><WrappedMessage message={messages.careerChallengeHeading}/></h3>
                {suggestedAssets[0] &&
                  <ChallengeCard suggestedAsset={suggestedAssets[0]} />
                }
            </div>
        </div>
    );
};

export default CareerTasks;
