// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerCategory
 */
export interface CareerCategory {
    /**
     * 
     * @type {number}
     * @memberof CareerCategory
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerCategory
     */
    categoryName: string;
    /**
     * SVG icon for the career category.
     * @type {string}
     * @memberof CareerCategory
     */
    readonly icon?: string;
    /**
     * This will help frontend to render career cards in different ways.
     * @type {string}
     * @memberof CareerCategory
     */
    colorGroup?: CareerCategoryColorGroupEnum;
    /**
     * Text definition of the career category.
     * @type {string}
     * @memberof CareerCategory
     */
    definition?: string;
}

export function CareerCategoryFromJSON(json: any): CareerCategory {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categoryName': json['category_name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'colorGroup': !exists(json, 'color_group') ? undefined : json['color_group'],
        'definition': !exists(json, 'definition') ? undefined : json['definition'],
    };
}

export function CareerCategoryToJSON(value?: CareerCategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'category_name': value.categoryName,
        'color_group': value.colorGroup,
        'definition': value.definition,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerCategoryColorGroupEnum {
    One = 'group-one',
    Two = 'group-two',
    Three = 'group-three',
    Four = 'group-four',
    Five = 'group-five'
}


