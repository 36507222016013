/* istanbul ignore file */
import * as LoginActions from 'auth/actions';
import bind from 'bind-decorator';
import update from 'immutability-helper';
import * as React from 'react';
import { connect } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import deepEqual from 'fast-deep-equal/es6';

import { getLoggedInStatus, getUserFullName, getUsername, getUserPermissions } from 'auth/selectors';
import { ItemsApi, OrganizationsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { LocationDescriptor } from 'history';
import { getItemSubTypeDefaultValues, getItemTypeMeta, ItemType } from 'items/models';
import {
    APIPermissions,
    Author,
    ContentSource,
    ItemCreationTypeEnum,
    ItemMetadata,
    ItemMetadataBackgroundKnowledgeEnum,
    ItemMetadataLicenseEnum,
    ItemMetadataTypeEnum,
    ItemMetadataUpdate,
    ItemMetadataUpdateBackgroundKnowledgeEnum,
    ItemMetadataUpdateLicenseEnum,
    ItemMetadataUpdateTypeEnum,
    ResourceRelationship,
} from 'labxchange-client';
import libraryMessages from 'library/displayMessages';
import {getEmptyItemMetadata} from 'library/models';
import {Redirect, RouteComponentProps} from 'react-router';
import {TagEditor} from 'search/components/TagEditor';
import {
    AuthorsField,
    Button,
    HtmlTextBox,
    Icon,
    Modal,
    ModalConfirmation,
    StandardPageLayout,
    ThinItemSection,
} from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import {showErrorMessage, showWarningMessage} from 'ui/components/GlobalMessageReporter/dispatch';
import {WrappedMessage} from 'utils';
import {EditorState, ExistingBlockEditor, NewBlockEditor} from '../BlockEditor/BlockEditor';
import {
    areRequiredFieldsComplete,
    NestedCreateOptions,
    newXBlockTypeForItemType,
    XBlockType
} from '../BlockEditor/models';
import {editorStateToApiRequest} from '../BlockEditor/serializers';
import {
    AgreementsField,
    BackgroundKnowledgeField,
    ContentSourceField,
    FeaturedImageField,
    LanguageField,
    LicenseField,
    PublicToggleField,
    SubjectAreaField,
} from '../ItemMetadataEditor';
import messages from './displayMessages';
import {UI_IS_SM} from 'ui/breakpoints';
import {RegistrationGate} from 'ui/components/RegistrationGate';
import {intl} from 'i18n';
import {ResourcesSection} from '../../../ui/components/Resources/ResourcesSection';

/**
 * This Enum references different modals ItemEditPage can handle.
 * We avoid using multiple state values on component like
 * "shouldShowThisModal", "shouldShowThisOtherModal", etc.
 */
enum ModalType {
    None = 0,
    ConfirmDeleteUnit,
    ConfirmSaveExit,
}

// This must be a subset of ItemMetadataTypeEnum
enum AddToType {
    Pathway = 'pathway',
    Assignment = 'assignment',
    CaseStudy = 'case_study',
    TeachingGuide = 'teaching_guide',
}

interface AddTo {
    id: string;
    type: AddToType;
    sectionId?: number;
    embed?: boolean;
}

interface MatchProps {
    itemKey: string;
    itemType: string;
    itemSubType: string;
}

interface ReduxStateProps {
    userPermissions?: APIPermissions;
    isLoggedIn: boolean;
    loggedInUsername: string;
    loggedInUserFullName: string;
}

interface Props extends RouteComponentProps<MatchProps>, ReduxStateProps {
}

interface State {
    itemMetadata?: ItemMetadata;
    blockEditorState?: EditorState;
    loading: boolean;
    modalType: ModalType;
    redirectTo?: LocationDescriptor;
    agreementsChecked: boolean;
    showPublicToggle: boolean;
    newImage?: File;
    newImageUrl?: string;
    // Are we currently saving changes:
    isSavingChanges: boolean;
    // Is this user allowed to change the 'content source' field of this item?
    canChangeContentSource: boolean;
    // Used in organizations, if can publish this item to public library
    canPublishOrgItemsToPublicLibrary: boolean;
    showErrors: boolean;
    showWarnings: boolean;
    availableContentSources: ContentSource[];
    saveExitLocation?: LocationDescriptor;
    isMobileView: boolean;
    saveExitSectionId?: number;
    saveExitEmbed?: boolean;
    blockDataChanged: boolean;
    originalEditorState?: EditorState;
    PIIChecked: boolean;
    enableNotificationCheck: boolean;
    notificationCheck: boolean;
    permissionDenied: boolean;
    isOrganizationItem: boolean;
}

export class ItemEditPageInternal extends React.PureComponent<Props, State> {
    private mediaQuery = UI_IS_SM;

    constructor(props: Props) {
        super(props);
        this.state = {
            isMobileView: false,
            loading: true,
            modalType: ModalType.None,
            agreementsChecked: false,
            showPublicToggle: false,
            isSavingChanges: false,
            canChangeContentSource: false,
            canPublishOrgItemsToPublicLibrary: false,
            showErrors: false,
            showWarnings: false,
            availableContentSources: [],
            blockDataChanged: false,
            saveExitEmbed: undefined,
            PIIChecked: false,
            permissionDenied: false,
            notificationCheck: false,
            enableNotificationCheck: false,
            isOrganizationItem: false,
        };
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        await this.loadData();
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevProps.match.params.itemKey !== this.props.match.params.itemKey
            || prevProps.match.params.itemType !== this.props.match.params.itemType
            || prevProps.match.params.itemSubType !== this.props.match.params.itemSubType
        ) {
            await this.loadData();
        }
    }

    private async loadData() {
        // reset the state in preparation for loading all the new data
        this.setState({
            agreementsChecked: false,
            blockEditorState: undefined,
            canChangeContentSource: false,
            canPublishOrgItemsToPublicLibrary: false,
            isSavingChanges: false,
            itemMetadata: undefined,
            loading: true,
            modalType: ModalType.None,
            newImage: undefined,
            newImageUrl: undefined,
            redirectTo: undefined,
            saveExitLocation: undefined,
            showErrors: false,
            showWarnings: false,
            showPublicToggle: false,
            blockDataChanged: false,
            isOrganizationItem: false,
        });
        if (!this.props.isLoggedIn) {
            return;
        }
        if (!this.props.match.params.itemKey) {
            const itemType = this.props.match.params.itemType as ItemType;
            const itemMetadata = getEmptyItemMetadata(itemType, new Date());
            let newImageUrl;

            const locationState = this.props.location.state;
            if (locationState) {
                if (locationState.title) {
                    itemMetadata.title = locationState.title;
                }
                if (locationState.description) {
                    itemMetadata.description = locationState.description;
                }
                if (locationState.language) {
                    itemMetadata.language = locationState.language;
                }
                if (locationState.freeFormTags) {
                    itemMetadata.freeFormTags = locationState.freeFormTags;
                }
                if (locationState.authors) {
                    itemMetadata.authors = locationState.authors;
                }
                if (locationState.license) {
                    itemMetadata.license = locationState.license;
                }
                if (locationState.imageUrl) {
                    itemMetadata.imageUrl = locationState.imageUrl;
                    newImageUrl = locationState.imageUrl;
                }
                if (locationState.subjectTags) {
                    itemMetadata.subjectTags = locationState.subjectTags;
                }
                if (locationState.backgroundKnowledge) {
                    itemMetadata.backgroundKnowledge = locationState.backgroundKnowledge;
                }
                if (locationState.funders) {
                    itemMetadata.funders = locationState.funders;
                }
                if (locationState.resources) {
                    itemMetadata.resources = locationState.resources;
                }
                if (locationState.originalLanguage) {
                    showWarningMessage(
                        <WrappedMessage
                            message={messages.languageChanged}
                            values={{
                                originalLanguage: locationState.originalLanguage,
                            }}
                        />
                    );
                }
            }
            // `canChangeContentSource` is required to load content sources
            this.setState({
                canChangeContentSource: this.getOrgSlug() !== null,
                canPublishOrgItemsToPublicLibrary: await this.getPublishToPublicPermission(),
                newImageUrl,
                itemMetadata,
                isOrganizationItem: this.getOrgSlug() !== null,
            }, async () => {
                await this.loadContentSources();
                this.setState({ loading: false });
            });
            return;
        }
        try {
            const item = await ItemsApi.read({id: this.props.match.params.itemKey});

            const showPublicToggle = item.userAttributes.showPublicToggle;

            if (item.userAttributes.canEditObject) {
                this.setState({
                    // If the user is the individual owner, then the content source was
                    // set automatically and they don't have permission to change it.
                    canChangeContentSource: !item.userAttributes.isItemOwner,
                    itemMetadata: item.metadata,
                    showPublicToggle,
                    notificationCheck: true,
                    enableNotificationCheck: true,
                }, async () => {
                    /// `itemMetadata` is needed for these functions
                    this.setState({
                        canPublishOrgItemsToPublicLibrary: await this.getPublishToPublicPermission(),
                        isOrganizationItem: this.getOrgSlug() !== null,
                    });
                    await this.loadContentSources();
                    this.setState({ loading: false });
                });
            } else {
                // They don't have permission; Redirect to the "view" mode and display an error message.
                this.setState({
                    itemMetadata: undefined,
                    loading: false,
                    permissionDenied: true,
                });
                return;
            }
        } catch (err) {
            this.setState({
                itemMetadata: undefined,
                loading: false,
            });
            showErrorMessage('Unable to load that item.', {exception: err});
            return;
        }
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }
        if (!this.props.isLoggedIn || this.state.permissionDenied) {
            return <RegistrationGate
                title={intl.formatMessage(messages.notLoginEditAssetAlertTitle)}
                body={intl.formatMessage(messages.notLoginEditAssetAlertBody)}
                primaryButtonText={!this.state.permissionDenied ? uiMessages.uiSignUp : undefined}
                secondaryButtonText={!this.state.permissionDenied ? uiMessages.uiSignIn : uiMessages.uiLogOut}
                secondaryButtonOnClick={!this.state.permissionDenied ? undefined : this.handleLogOut}
                image='/assets/images/access.svg'
              />;
        }
        if (this.state.loading) { return this.renderLoading(); }
        if (this.state.itemMetadata === undefined) {
            return <StandardPageLayout><p>Error.</p></StandardPageLayout>;
        }

        const isPublic = this.isPublic();

        const pageTitle = this.isNewItem() ?
            messages.pageTitleCreateItemPage :
            messages.pageTitleEditItemPage;
        const pageTitleValues: {[key: string]: string} = this.isNewItem() ?
            {assetType: intl.formatMessage(getItemTypeMeta(this.state.itemMetadata.type).name)} :
            {name: this.state.itemMetadata.title};

        // Once content has loaded:
        return (
            <StandardPageLayout
                pageTitle={pageTitle}
                pageTitleValues={pageTitleValues}
                headerFeature={this.renderTitleEdit()}
                headerFeatureContainer={true}
                headerContainerPaddingEnabled={false}
                otherClassName='item-edit-page'
            >
                <h1 className='sr-only'>
                    <WrappedMessage message={pageTitle} values={pageTitleValues}/>
                </h1>
                <div className='row'>
                    {/* The description field and the actual content edit widget
                        (which varies depending on the XBlock type).
                        These are always grouped together (vertically) on all device sizes. */}
                    <div className='col-12 col-lg-9'>
                        {this.renderDescriptionEdit()}
                        {this.renderBlockEditor()}
                    </div>
                    <div className='col-12 col-lg-3'>
                        {/*
                            Minor metadata fields: Language, Subject, Background Knowledge, License
                            These are on the right (in a single column) on desktop, and below the Block
                            Editor otherwise (in a two column layout on tablet, and one column on phones)
                        */}
                        <div className='row'>
                            {/* Language */}
                            <div className='col-12 col-sm-6 col-lg-12'>
                                <LanguageField
                                    value={this.state.itemMetadata.language}
                                    onUpdate={this.onUpdateLanguage}
                                />
                            </div>
                            {/* Subject Area */}
                            <div className='col-12 col-sm-6 col-lg-12'>
                                <SubjectAreaField
                                    required={isPublic}
                                    showErrors={this.state.showErrors}
                                    onUpdate={this.onUpdateSubjectTags}
                                    selectedSubjectTags={this.state.itemMetadata!.subjectTags}
                                />
                            </div>
                            {/* Background Knowledge */}
                            {this.shouldShowBackgroundKnowledgeField() &&
                                <div className='col-12 col-sm-6 col-lg-12'>
                                    <BackgroundKnowledgeField
                                        required={isPublic}
                                        showErrors={this.state.showErrors}
                                        value={this.state.itemMetadata!.backgroundKnowledge}
                                        onChange={this.onUpdateBackgroundKnowledge}
                                    />
                                </div>
                            }
                            {/* License */}
                            <div className='col-12 col-sm-6 col-lg-12'>
                                <LicenseField
                                    value={this.state.itemMetadata!.license}
                                    onChange={this.onUpdateLicense}
                                    contentSource={this.selectedSource || null}
                                />
                            </div>
                            {/* Source (for organization-owned content) */}
                            {this.getOrgSlug() !== null &&
                                <div className='col-12 col-sm-6 col-lg-12'>
                                    <ContentSourceField
                                        selectedSourceId={this.selectedSourceId}
                                        contentSources={this.state.availableContentSources}
                                        allowChangingSource={this.state.canChangeContentSource}
                                        onUpdateContentSource={this.updateContentSource}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-12 col-lg-9'>
                        {/* The remaining fields */}

                        {(this.props.userPermissions?.library.canCreateLinkItem && this.state.itemMetadata.type !== ItemMetadataTypeEnum.Link) &&
                            <ResourcesSection
                              item={this.state.itemMetadata}
                              updateResources={this.onUpdateResources}
                            />
                        }
                        {this.renderAuthorsEdit()}
                        {this.state.isMobileView? <>
                            {this.renderFreeFormTagsEdit()}
                            {this.renderFeaturedImageEdit()}
                            </>:
                            <div className='tags-featured-image-container'>
                                <div className='side-meta-wrapper'>
                                    {this.renderFreeFormTagsEdit()}
                                </div>
                                <div className='side-meta-wrapper'>
                                    {this.renderFeaturedImageEdit()}
                                </div>
                            </div>
                        }

                        {this.getPublicEdit()}
                        {this.getAgreementsField()}
                        {this.renderActions()}
                    </div>
                </div>
                {this.renderModals()}
            </StandardPageLayout>
        );
    }

    private get selectedSourceId(): number {
        return this.source ? parseInt(this.source.id, 10) : 0;
    }

    private get source() {
        return this.state.itemMetadata && this.state.itemMetadata!.source;
    }

    @bind private updateContentSource(contentSource: ContentSource) {
        if (this.state.canChangeContentSource && this.state.itemMetadata) {
            this.setState({itemMetadata: update(this.state.itemMetadata,
                {source: {$set: contentSource }},
            )});
        } else if (!this.state.canChangeContentSource) {
            showErrorMessage('Not allowed to change content source');
        }
    }

    private get selectedSource(): ContentSource|null {
        if (this.source) {
            return this.state.availableContentSources!.find(
                (c: ContentSource) => c.id === this.source!.id,
            ) || null;
        }
        return null;
    }

    private async loadContentSources() {
        const slug = this.getOrgSlug();
        if (!slug) { return null; }
        let contentSources: ContentSource[];
        try {
            contentSources = await OrganizationsApi.contentSources({id: slug});
            this.setState({ availableContentSources: contentSources });
            this.updateContentSource(this.source || contentSources[0]);
        } catch (error) {
            showErrorMessage('Could not fetch content sources', { exception: error });
        }
        return;
    }

    /**
     * If this item is being created for an organization or is from an organization,
     * this is that organization's ID.
     * Note that this will be set to the organization's ID even in the case where the
     * item has an individual owner but the "content source" was set automatically to
     * an organization - in that case it still belongs to an individual user, not to
     * the org.
     */
    private getOrgSlug(): string|null {
        if (this.props.location.state && this.props.location.state.orgSlug) {
            // This is a new item being created from an org dashboard.
            return this.props.location.state.orgSlug as string;
        } else {
            // This is an existing item being edited.
            const metadata = this.state.itemMetadata;
            return (
                (metadata && metadata.source) ?
                (metadata.source.sourceOrganizations && metadata.source.sourceOrganizations[0].organization.slug)
                : null
            );
        }
    }

    private async getPublishToPublicPermission() {
        const orgSlug = this.getOrgSlug();
        if (orgSlug === null) {
            return false;
        }
        const orgResponse = await OrganizationsApi.read({id: orgSlug!});
        return orgResponse.permissions.canPublishContentToPublicLibraryObject;
    }

    private shouldShowBackgroundKnowledgeField(): boolean {
        if (this.state.itemMetadata) {
            // hide field on narrative types
            return this.state.itemMetadata.type !== ItemType.Narrative;
        } else {
            return true;
        }
    }

    private renderLoading() {
        return (
            <StandardPageLayout headerFeature={
                <h1><WrappedMessage message={uiMessages.uiLoading}/></h1>
            }>
                <p><WrappedMessage message={uiMessages.uiLoading}/></p>
            </StandardPageLayout>
        );
    }

    private enablePIICheck () {
        return this.state.itemMetadata!.type === ItemMetadataTypeEnum.Simulation;
    }


    private getAgreementsField() {
        return (
            <AgreementsField
                isChecked={this.state.agreementsChecked}
                showErrors={this.state.showErrors}
                onUpdate={(checked: boolean) => this.setState(
                    {agreementsChecked: checked})
                }
                enablePIICheck={this.enablePIICheck() && this.props.userPermissions?.library.canSendPii}
                isPIIChecked={this.state.itemMetadata?.sendPiiMetadata}
                onPIIUpdate={this.onUpdatePIICheck}
                enableNotificationCheck={this.state.enableNotificationCheck}
                isNotificationChecked={this.state.notificationCheck}
                onNotificationCheckUpdate={this.onUpdateNotificationCheck}
            />
        );
    }

    @bind private onUpdatePIICheck(checked: boolean) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {sendPiiMetadata: {$set: checked}},
        )});
    }

    @bind private onUpdateNotificationCheck(checked: boolean) {
        this.setState({
            notificationCheck: checked
        });
    }

    private renderFeaturedImageEdit() {
        return (
            <FeaturedImageField
                required={this.isPublic()}
                showErrors={this.state.showErrors}
                selectedFile={this.state.newImage}
                defaultValue={
                    this.state.itemMetadata && (this.state.itemMetadata.imageUrl || '')
                }
                onUpdate={this.onUpdateFeaturedImage}
            />
        );
    }

    private renderFreeFormTagsEdit() {
        return (
            <TagEditor
                required={this.isPublic()}
                showErrors={this.state.showErrors}
                onChange={this.onUpdateFreeFormTags}
                tags={this.state.itemMetadata!.freeFormTags}
            />
        );
    }

    private getAddToInfo(): AddTo|null|any {
        const params = new URLSearchParams(window.location.search);
        const addToId: string|null = params.get('add-to-id');
        const addToType: AddToType|null = params.get('add-to-type') as AddToType|null;
        const sectionIdRaw: string|null = params.get('add-to-section');
        const embedRaw: string|null = params.get('add-to-embed');

        let sectionId;
        if (sectionIdRaw !== null) {
            sectionId = parseInt(sectionIdRaw, 10);
        }

        const embed = (embedRaw !== null);

        if (addToId === null || addToType === null) {
            return null;
        }

        // validate that the value is indeed a valid AddToType
        if (!Object.values(AddToType).includes(addToType)) {
            return null;
        }

        return {
            id: addToId,
            type: addToType,
            sectionId,
            embed,
        };
    }

    /**
     * This renders a button to return to the pathway/assignment/etc, if this
     * item edit page is part of the edit pathway/assignment -> add new content
     * flow.
     */
    private renderBackToItem() {
        const info = this.getAddToInfo();
        if (info === null) {
            return null;
        }

        let url;
        let label;
        switch (info.type as AddToType) {
            case AddToType.Pathway: {
                url = ROUTES.Library.PATHWAY_EDIT_SLUG(info.id);
                label = messages.backToPathway;
                break;
            }

            case AddToType.Assignment: {
                url = ROUTES.Library.ITEM_EDIT_SLUG(info.id);
                label = messages.backToAssignment;
                break;
            }

            case AddToType.CaseStudy: {
                url = ROUTES.Library.ITEM_EDIT_SLUG(info.id);
                label = messages.backToCaseStudy;
                break;
            }

            case AddToType.TeachingGuide: {
                url = ROUTES.Library.ITEM_EDIT_SLUG(info.id);
                label = messages.backToTeachingGuide;
                break;
            }

            default: {
                return null;
            }
        }

        return (
            <Button
                className='back-to-header-button'
                btnStyle='outline'
                href={url}
                label={label}
                icon='chevron-left'
            />
        );
    }

    /** Render the editable title at the top of the page */
    private renderTitleEdit() {
        const showErrors = this.state.showErrors && !this.state.itemMetadata?.title;
        const itemType = intl.formatMessage(
            getItemTypeMeta(this.state.itemMetadata!.type).name,
            {count: this.state.itemMetadata!.itemCount}
        );
        return (
            <div className='row item-edit-title'><div className='col'>
                {this.renderBackToItem()}
                <WrappedMessage message={messages.ariaTitleInputLabel} values={{itemType}}>
                {(label: string) => (
                    <WrappedMessage
                        message={messages.titleInputPlaceholder}
                        values={{itemType}}
                    >
                    {(placeholder: string) => (
                        <input
                            className={`${showErrors ? 'error' : ''}`}
                            onChange={(e) => this.onUpdateTitle(e.currentTarget.value)}
                            aria-label={label}
                            placeholder={placeholder + '*'}
                            type='text'
                            defaultValue={this.state.itemMetadata!.title}
                        />
                    )}
                    </WrappedMessage>
                )}
                </WrappedMessage>
            </div></div>
        );
    }

    private isPublic(): boolean {
        return Boolean(this.state.itemMetadata?.isPublic);
    }

    private handleLogOut() {
        LoginActions.logout();
    }

    private renderDescriptionEdit() {
        const isRequired = this.isPublic();

        return (
            <ThinItemSection>
                <WrappedMessage
                    message={getItemTypeMeta(this.state.itemMetadata!.type).name}
                    values={{count: this.state.itemMetadata!.itemCount}}
                >
                    {(itemType: string) => (
                        <WrappedMessage
                            message={messages.descriptionTextAreaPlaceholder}
                            values={{itemType: String(itemType).toLowerCase()}}
                        >
                            {(placeholder: string) => (
                                <HtmlTextBox
                                    label={<WrappedMessage
                                        message={messages.descriptionTextAreaLabel}
                                        values={{itemType}}/>}
                                    placeholder={placeholder}
                                    onChange={(value) => this.onUpdateDescription(value)}
                                    defaultValue={this.state.itemMetadata!.description}
                                    description={this.state.itemMetadata!.type === 'narrative' ?
                                    <WrappedMessage message={messages.narrativeEditorDescriptionField} />
                                    : null}
                                    editorStyle={HtmlTextBox.EditorStyle.Medium}
                                    required={isRequired}
                                    showErrors={this.state.showErrors}
                                    extraClassName='item-description-editor'
                                />
                            )}
                        </WrappedMessage>
                )}
                </WrappedMessage>
            </ThinItemSection>
        );
    }

    private renderBlockEditor() {
        const itemSubType = this.props.match.params.itemSubType;
        const sharedProps = {
            editorState: this.state.blockEditorState,
            onEditorStateChanged: this.onBlockEditorStateChanged,
            showErrors: this.state.showErrors,
            showWarnings: this.state.showWarnings,
            onNestedCreate: this.onNestedCreate,
            saveItem: this.saveItem,
            onForceSave: this.forceSave,
            loggedInUsername: this.props.loggedInUsername,
            itemMetadata: this.state.itemMetadata,
            onUpdateAuthors: this.onUpdateAuthors,
        };
        if (this.isNewItem()) {
            const itemType = this.props.match.params.itemType as ItemType;
            const xblockType = newXBlockTypeForItemType(itemType);
            const locationStateDefaults = this.props.location.state ?
                this.props.location.state.blockEditorDefaults :
                {};
            const defaultValues = {
                ...getItemSubTypeDefaultValues(itemType, itemSubType),
                ...locationStateDefaults,
            };
            return <NewBlockEditor
                xblockType={xblockType}
                defaultValues={defaultValues}
                {...sharedProps}
            />;
        } else {
            return <ExistingBlockEditor
                itemId={this.state.itemMetadata!.id}
                xblockId={this.state.itemMetadata!.id}
                {...sharedProps}
            />;
        }
    }

    private getPublicEdit() {
        if (this.isNewItem()) {
            // We need to check props.userPermissions here, rather than on
            // init, because userPermissions is dynamically loaded with redux
            // and may not be set on init.
            if (!this.props.userPermissions?.library.canMakeItemPublic
                && !this.state.canPublishOrgItemsToPublicLibrary) {
                return null;
            }
        } else if (!this.state.canPublishOrgItemsToPublicLibrary && !this.state.showPublicToggle) {
            return null;
        }
        return (
            <PublicToggleField
                onUpdate={this.onUpdatePublic}
                showNote={true}
                isPublic={Boolean(this.state.itemMetadata?.isPublic)}
                disabled={this.state.isOrganizationItem && !this.state.canPublishOrgItemsToPublicLibrary}
            />
        );
    }

    private renderActions() {
        const itemType = intl.formatMessage(
            getItemTypeMeta(this.state.itemMetadata!.type).name,
            {count: this.state.itemMetadata!.itemCount}
        );
        return (
            <div className='unit-actions'>
                {/* Delete Button (if editing an existing item) */}
                {!this.isNewItem() &&
                    <button type='button'
                        disabled={this.state.isSavingChanges}
                        className='btn btn-link unit-delete-button'
                        onClick={() => this.setState({modalType: ModalType.ConfirmDeleteUnit})}>
                        <Icon name='trashcan'/>
                        <WrappedMessage
                            message={messages.deleteItemButton}
                            values={{itemType: String(itemType).toLowerCase()}}/>
                    </button>
                }

                {/* Save Draft Button */}
                <button className='btn btn-outline-primary unit-save-draft-button hide-on-tablet-and-below'>
                    <WrappedMessage message={messages.saveDraftButton}/>
                </button>

                {/* Save/Publish Button */}
                <Button
                    btnStyle='primary'
                    className={`unit-publish-publicly-button ${this.canPublish() ? 'can-publish' : 'cannot-publish'}`}
                    onClick={this.saveItem}
                    disabled={this.isSaveButtonDisabled()}
                    hasLoader={true}
                    {...this.getPublishButtonLabel()}
                />
            </div>
        );
    }

    private getPublishButtonLabel(): { label: MessageDescriptor, labelValues?: any } {
        if (!this.state.itemMetadata) {
            throw new Error(`missing Item Metadata!`);
        }
        const shouldPublish = this.props.userPermissions?.library.canMakeItemPublic && this.state.itemMetadata?.isPublic;
        const addToType = this.getAddToInfo()?.type;
        // const labelText = intl.formatMessage(props.label, props.labelValues);
        if (this.state.isSavingChanges) {
            return { label: messages.publishPubliclySavingButton };
        } else {
            if (addToType) {
                const typeMeta = getItemTypeMeta(addToType as ItemMetadataTypeEnum);
                return {
                    label: shouldPublish ? messages.publishItemButton : messages.saveItemButton,
                    labelValues: {
                        itemType: intl.formatMessage(typeMeta.simpleName? typeMeta.simpleName : typeMeta.name),
                    },
                };
            } else {
                const typeMeta = getItemTypeMeta(this.state.itemMetadata.type);
                if (!shouldPublish) {
                    return {
                        label: messages.saveButton,
                        labelValues: {
                            itemType: intl.formatMessage(typeMeta.simpleName? typeMeta.simpleName : typeMeta.name),
                        },
                    };
                } else if (this.state.itemMetadata?.isPublic) {
                    return { label: messages.publishPubliclyButton };
                } else {
                    return {
                        label: messages.publishPrivatelyButton,
                        labelValues: {
                            itemType: intl.formatMessage(typeMeta.simpleName? typeMeta.simpleName : typeMeta.name),
                        },
                    };
                }
            }
        }
    }

    private renderModals() {
        switch (this.state.modalType) {
            case ModalType.ConfirmDeleteUnit: {
                return (
                    <ModalConfirmation
                        onCanceled={() => this.setState({modalType: ModalType.None})}
                        onConfirmed={() => this.deleteItemMetadata()}
                        title={libraryMessages.deleteItemConfirm}
                        titleValues={{title: this.state.itemMetadata!.title}}
                        body={libraryMessages.deleteItemWarn}
                        confirmButtonText={uiMessages.uiConfirmationButton}
                        cancelButtonText={uiMessages.uiCancelButton}
                    />
                );
            }

            case ModalType.ConfirmSaveExit: {
                let saveQuestion = messages.saveAssignmentQuestion;
                let saveExplanation = messages.saveAssignmentExplanation;
                if (this.state.itemMetadata?.type === ItemType.CaseStudy) {
                    saveQuestion = messages.saveCaseStudyQuestion;
                    saveExplanation = messages.saveCaseStudyExplanation;
                } else if (this.state.itemMetadata?.type === ItemType.TeachingGuide) {
                    saveQuestion = messages.saveTeachingGuideQuestion;
                    saveExplanation = messages.saveTeachingGuideExplanation;
                }

                return (
                    <Modal
                        className='modal-publish-exit'
                        showBackButton={false}
                        onRequestClose={() => this.setState({modalType: ModalType.None})}
                        content={
                            <>
                                <div className='warning-icon'></div>
                                <div className='title-text'>
                                    <WrappedMessage
                                        message={saveQuestion}
                                    />
                                </div>
                                <div className='body-text'>
                                    <WrappedMessage
                                        message={saveExplanation}
                                    />
                                    {this.isNewItem() &&
                                        <AgreementsField
                                            isChecked={this.state.agreementsChecked}
                                            showErrors={this.state.showErrors}
                                            onUpdate={(checked: boolean) => this.setState(
                                                {agreementsChecked: checked})}
                                        />
                                    }
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <Button
                                    btnStyle='outline'
                                    label={libraryMessages.leavePublishModalBackToEditButton}
                                    onClick={() => this.setState({modalType: ModalType.None})}
                                />
                                <Button
                                    btnStyle='primary'
                                    onClick={this.forceSaveAndNavigate}
                                    forceLoading={this.state.isSavingChanges}
                                    disabled={(!this.state.agreementsChecked && this.isNewItem())
                                        || this.state.loading || this.state.isSavingChanges
                                    }
                                    label={libraryMessages.leavePublishModalConfirmButton}
                                />
                            </>
                        }
                    />
                );
            }

            case ModalType.None:
            default: {
                return;
            }
        }
    }

    private renderAuthorsEdit() {
        return (
            <AuthorsField
                isNew={this.isNewItem()}
                authors={this.state.itemMetadata ? this.state.itemMetadata.authors : []}
                onUpdate={this.onUpdateAuthors}
                currentUser={{
                    username: this.props.loggedInUsername,
                    fullName: this.props.loggedInUserFullName,
                }}
                canEditOriginalAuthors={this.props.userPermissions?.library.canEditItemOriginalAuthors}
            />
        );
    }

    /**
     * This handles when the xblock editor requests to create a new item. This
     * should run the flow to force save, add params to `location` so it knows
     * where to add the new content to, and navigate.
     */
    @bind private onNestedCreate(location: LocationDescriptor, options: NestedCreateOptions) {
        this.setState({
            modalType: ModalType.ConfirmSaveExit,
            saveExitLocation: location,
            saveExitSectionId: options.sectionId,
            saveExitEmbed: options.embed,
        });
    }

    @bind private async forceSave(callback?: (savedItem?: ItemMetadata) => void) {
        const itemMetadata = this.state.itemMetadata;
        if (itemMetadata === undefined) { return; }

        // only supported for these item types
        if (![
                ItemType.AnnotatedVideo,
                ItemType.Assignment,
                ItemType.CaseStudy,
                ItemType.TeachingGuide,
            ].includes(itemMetadata.type)) {
            return;
        }

        // params, and navigate away
        let title = itemMetadata.title;
        if (!title) {
            if (itemMetadata.type === ItemType.Assignment) {
                title = intl.formatMessage(messages.newAssignment);
            } else if (itemMetadata.type === ItemType.CaseStudy) {
                title = intl.formatMessage(messages.newCaseStudy);
            } else if (itemMetadata.type === ItemType.TeachingGuide) {
                title = intl.formatMessage(messages.newCaseStudy);
            }
        }

        // if not ready to publish, then set to private so we can publish anyway
        const isPublic = this.requiredFieldsComplete() ? itemMetadata.isPublic : false;

        this.setState({
            itemMetadata: update(itemMetadata, {
                isPublic: {$set: isPublic},
                title: {$set: title},
            }),
            isSavingChanges: true,
        }, async () => {
            const id = await this.doSaveItem(true);
            if (id === undefined) {
                this.setState({
                    isSavingChanges: false,
                });
                return;
            }

            this.setState({
                itemMetadata: update(this.state.itemMetadata!, {id: {$set: id || ''}}),
            }, () => {
                if (callback) { callback(this.state.itemMetadata); }
            });

        });
    }

    @bind private forceSaveAndNavigate() {
        this.forceSave(() => {
            let location = this.state.saveExitLocation;
            const newItemMetadata = this.state.itemMetadata;
            if (location === undefined || newItemMetadata === undefined) {
                return;
            }
            // Cool hack to ensure the url to the asset edit page (in
            // case we just saved a new asset).
            const url = ROUTES.Library.ITEM_EDIT_SLUG(newItemMetadata.id);
            window.history.replaceState(null, '', url);

            // see for typing spec:
            // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/54ab8fc24cbf263e7608d2bc56cad6283bc2aa8d/types/history/index.d.ts
            // Pull out the existing search params (this is to preserve any
            // existing params).
            let search;
            if (typeof(location) === 'string') {
                search = new URLSearchParams(new URL(location, window.location.origin).search);
            } else {
                search = new URLSearchParams(location.search);
            }

            // Add search params so we can track which item we came from.
            search.append('add-to-id', newItemMetadata.id);
            search.append('add-to-type', newItemMetadata.type);
            if (this.state.saveExitSectionId !== undefined) {
                search.append('add-to-section', this.state.saveExitSectionId.toString());
            }
            if (this.state.saveExitEmbed) {
                search.append('add-to-embed', 'true');
            }

            // Update the location with the added search params.
            if (typeof(location) === 'string') {
                location = (new URL(location, window.location.origin)).pathname + '?' + search.toString();
            } else {
                location.search = search.toString();
            }
            this.setState({
                redirectTo: location,
            });

        });
    }

    @bind private onUpdateAuthors(authors: Author[]) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {authors: {$set: Array.from(authors)}},
        )});
    }

    @bind private onUpdateFreeFormTags(tags: string[]) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {freeFormTags: {$set: Array.from(tags)}},
        )});
    }

    @bind private onUpdateTitle(data: string) {
        this.setState({
            itemMetadata: update(this.state.itemMetadata!, {title: {$set: data}}),
        });
    }

    @bind private onUpdatePublic(data: boolean) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {isPublic: {$set: data}},
        )});
    }

    @bind private onUpdateDescription(data: string) {
        this.setState({
            itemMetadata: update(this.state.itemMetadata!, {description: {$set: data}}),
        });
    }

    /** The user has made edits to the actual XBlock content. */
    @bind private onBlockEditorStateChanged(newState: EditorState) {
        let isChange = this.isNewItem();  // default to true if new item because should always update if new
        if(newState.blockType === XBlockType.Image && !this.state.newImage) {
            this.setState({newImage: newState.imageFile});
            isChange = true;
        }

        // Set or check for changes from the original state.
        if (this.state.originalEditorState === undefined) {
            this.setState({ originalEditorState: newState });
        } else if (!deepEqual(this.state.originalEditorState, newState)) {
            isChange = true;
        }

        this.setState({
            blockEditorState: newState,
            blockDataChanged: isChange,
        });
    }

    @bind private onUpdateLicense(data: ItemMetadataLicenseEnum) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {license: {$set: data}},
        )});
    }

    @bind private onUpdateBackgroundKnowledge(data: ItemMetadataBackgroundKnowledgeEnum) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {backgroundKnowledge: {$set: data}},
        )});
    }

    @bind private onUpdateFeaturedImage(newFile: File|undefined, newUrl: string) {
        this.setState({newImage: newFile});
        if (newFile === undefined && newUrl !== this.state.itemMetadata!.imageUrl) {
            this.setState({itemMetadata: update(this.state.itemMetadata!,
                {imageUrl: {$set: ''}},
            )});
        }
    }

    @bind private onUpdateLanguage(data: string) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {language: {$set: data}},
        )});
    }

    @bind private onUpdateSubjectTags(selectedTagIdSet: ReadonlySet<string>) {
        this.setState({itemMetadata: update(this.state.itemMetadata!,
            {subjectTags: {$set: Array.from(selectedTagIdSet)}},
        )});
    }

    @bind private onUpdateResources(resources: ResourceRelationship[]) {
        this.setState({
            itemMetadata: update(this.state.itemMetadata!,
                {resources: {$set: Array.from(resources)}},
            )
        });
    }

    @bind private onDeleteResources(deleteIndex: number) {
        let updatedItems: ResourceRelationship[];
        if (this.state.itemMetadata?.resources) {
            updatedItems = this.state.itemMetadata.resources;
            updatedItems.splice(deleteIndex, 1);
            this.setState({
                itemMetadata: update(this.state.itemMetadata!,
                    {resources: {$set: Array.from(updatedItems)}},
                )
            });
        }
    }

    /**
     * Delete the item and redirect to the dashboard.
     */
    @bind private async deleteItemMetadata() {
        const itemMetadata = this.state.itemMetadata;
        if (itemMetadata === undefined) {
            throw new Error('Cannot delete when itemMetadata is not loaded.');
        }

        const itemId = this.props.match.params.itemKey;
        if (itemId) {
            // If editing an existing unit, delete it:
            try {
                await ItemsApi._delete({id: itemId});
            } catch (err) {
                this.setState({modalType: ModalType.None});
                showErrorMessage(<WrappedMessage message={libraryMessages.deleteItemErrorMessage} />, {
                    exception: err,
                    confirmText: uiMessages.uiOk,
                });
                return;
            }
        }
        this.setState({redirectTo: ROUTES.Dashboard.HOME});
    }

    /**
     * Save the item and return the new id
     */
    @bind private async doSaveItem(force: boolean = false): Promise<string|undefined> {
        const newData = this.state.itemMetadata;
        if (newData === undefined) {
            throw new Error('Cannot update when itemMetadata is not loaded.');
        }

        if (!this.requiredFieldsComplete() && !force) {
            this.setState({
                showErrors: true,
            });
            return;
        }

        this.setState({isSavingChanges: true});

        let itemId;
        let createResult;
        if (this.isNewItem() && this.state.itemMetadata !== undefined) {
            // Create a new item:
            try {
                createResult = await ItemsApi.create({data: {
                    type: this.state.itemMetadata.type as unknown as ItemCreationTypeEnum,
                    title: this.state.itemMetadata.title,
                    sourceId: (
                        this.state.itemMetadata.source ? parseInt(this.state.itemMetadata.source.id, 10) : undefined
                    ),
                }});
                itemId = createResult.metadata.id;
            } catch (err) {
                this.setState({isSavingChanges: false});
                showErrorMessage('Unable to create that item!', {exception: err});
                return;
            }
        } else {
            // We are updating an existing item
            itemId = this.state.itemMetadata!.id;
        }

        // Note that we have a special case for checking for changes in assessment item types. This is because
        // the assessment editor manages its own state, and we haven't found a good method yet for determining
        // if it has changed from the initial state. So, we just assume it was updated to avoid losing data.
        const isBlockChange = (
            this.state.blockDataChanged
            || this.state.itemMetadata?.type === ItemType.Assessment
            || this.state.itemMetadata?.type === ItemType.Question
        );

        // Update the item content itself:
        if (this.state.blockEditorState &&
            this.state.blockEditorState.blockType !== XBlockType.Other &&
            isBlockChange
        ) {
            try {
                const data = await editorStateToApiRequest(this.state.blockEditorState, itemId);
                data.display_name = newData.title; // display_name is common to all supported xblocks
                await ItemsApi.editXblockSave({id: itemId, blockKey: itemId, data});
            } catch (err) {
                this.setState({isSavingChanges: false});
                if (this.state.itemMetadata?.type === ItemType.Video) {
                    // We can't save video if transcript validation fails.
                    // Otherwise it wont store whole Xblock data
                    // and render video incorrectly
                    const resp = await err.json();
                    showErrorMessage(resp.join('\n'), {exception: err});
                    return undefined;
                } else {
                    // Still continue, so we save the metadata changes at least.
                    showErrorMessage('Unable to save changes to item content!', {exception: err});
                }
            }
        }

        if (this.state.newImage) {
            // If newImage (Featured Image, shown on cards in search results) is defined, upload it
            await ItemsApi.imageCreate({id: itemId, image: this.state.newImage});
        } else if (this.state.newImageUrl) {
            // When importing video from YouTube, we use external image URL instead of an image file.
            await ItemsApi.imageCreate({id: itemId, imageUrl: this.state.newImageUrl});
        } else if (newData.imageUrl === '') {
            // Delete the featured image if there was one (see onUpdateFeaturedImage)
            // This should actually never happen because featured image is a required field,
            // but leaving this here in case it becomes optional in the future.
            await ItemsApi.imageDelete({id: itemId});
        }

        const metadata: ItemMetadataUpdate = {
            title: newData.title,
            backgroundKnowledge: newData.backgroundKnowledge as unknown as ItemMetadataUpdateBackgroundKnowledgeEnum,
            description: newData.description,
            language: newData.language,
            learningObjectives: newData.learningObjectives,
            subjectTags: newData.subjectTags,
            freeFormTags: newData.freeFormTags,
            license: (newData.license as unknown) as ItemMetadataUpdateLicenseEnum,
            type: ItemMetadataUpdateTypeEnum.Pathway, // XXX: why is this hardcoded to pathway?
            isPublic: newData.isPublic,
            authors: newData.authors.filter((x: Author) => (x.username || x.fullName)),
            sourceId: newData.source ? parseInt(newData.source.id, 10) : undefined,
            sendPiiMetadata: newData.sendPiiMetadata,
            isBlockChange,
            notifyUpdate: this.state.notificationCheck,
            resources: newData.resources
        };
        try {
            await ItemsApi.partialUpdate({
                id: itemId,
                data: metadata,
            });
            if (createResult) {
                this.setState({
                    itemMetadata: update(this.state.itemMetadata, {
                        id: {$set: createResult.metadata.id},
                        title: {$set: createResult.metadata.title},
                        source: {$set: createResult.metadata.source},
                    }),
                });
            }
        } catch (err) {
            this.setState({isSavingChanges: false});
            showErrorMessage('Unable to save changes to item metadata!', {exception: err});
            return;
        }

        return itemId;
    }

    private requiredFieldsName(editorState: EditorState, isPublic: boolean) {
        const result = [];
        switch (editorState.blockType) {
            case XBlockType.Assignment:
                result.push(messages.assignmentRequiredFields);
                break;
            case XBlockType.Image:
                result.push(messages.imageRequiredFields);
                break;
            case XBlockType.Html:
                result.push(messages.htmlRequiredFields);
                break;
            case XBlockType.Document:
                result.push(messages.documentRequiredFields);
                break;
            case XBlockType.Narrative:
                result.push(messages.narrativeRequiredFields);
                break;
            case XBlockType.Problem:
                result.push(messages.problemRequiredFields);
                break;
            case XBlockType.Video:
            case XBlockType.LXVideo:
                if (!editorState.youTubeId && editorState.html5Sources.length === 0){
                    result.push(messages.videoRequiredFields);
                }
                if (isPublic && (Object.keys(editorState.transcripts).length === 0)) {
                    result.push(messages.videoTranscriptsRequiredField);
                }
                break;
            case XBlockType.Audio:
                result.push(messages.audioRequiredFields);
                break;
            case XBlockType.AnnotatedVideo:
                if (!editorState.youTubeId && editorState.html5Sources.length === 0){
                    result.push(messages.videoRequiredFields);
                }
                if (isPublic && (Object.keys(editorState.transcripts).length === 0)) {
                    result.push(messages.videoTranscriptsRequiredField);
                }
                break;
            case XBlockType.Link:
                if(!editorState.documentUrl){
                    result.push(messages.googleDocumentRequiredFields);
                }
                break;
            default:
                result.push(messages.unknownXBlockType);
                break;
        }
        return result;
    }

    private renderModalWithIncompleteFields(privateItems: MessageDescriptor[], publicItems: MessageDescriptor[]) {
        const metadata = this.state.itemMetadata;
        if (metadata === undefined) {
            return false;
        }

        let options = {};
        let items: MessageDescriptor[] = [...privateItems];

        if (metadata.isPublic) {
            if (items.length === 0) {
                options = {
                    confirmText: messages.publishAsPrivate,
                    cancelText: messages.backToEdit,
                    onConfirm: () => {
                        this.setState({
                            itemMetadata: update(this.state.itemMetadata, {
                                isPublic: {$set: false},
                            }),
                        }, () => this.saveItem());
                    }
                };
            }

            items = items.concat(publicItems);
        }

        const messageBody = <WrappedMessage
            message={messages.missingRequiredFields}
            values={{ itemType: this.state.itemMetadata?.type }}
        />;
        const title = <WrappedMessage message={messages.missingRequiredFieldsTitle} />;

        const bodyHtmlAppend = <ul className='items-list'>{items.map(item => <li><WrappedMessage message={item} /></li>)}</ul>;
        showWarningMessage(messageBody, {
            title,
            bodyHtmlAppend,
            ...options
        });
        return;
    }

    private getIncompletePrivateFieldsMessage() {
        const metadata = this.state.itemMetadata;
        const items: MessageDescriptor[] = [];
        if (metadata === undefined) {
            return items;
        }
        if (!metadata.title) { items.push(messages.missingTitle); }
        if (!this.state.agreementsChecked) { items.push(messages.missingTOS); }
        if (this.state.blockEditorState && !areRequiredFieldsComplete(this.state.blockEditorState, metadata.isPublic ?? false)) {
            items.push(...this.requiredFieldsName(this.state.blockEditorState, metadata.isPublic ?? false));
        }
        return items;
    }

    private getIncompletePublicFieldsMessage() {
        const metadata = this.state.itemMetadata;
        const items: MessageDescriptor[] = [];
        if (metadata === undefined) {
            return items;
        }
        if (metadata.isPublic) {
            if (metadata.freeFormTags.length === 0) { items.push(messages.missingTags); }

            if (this.shouldShowBackgroundKnowledgeField() === false || !metadata.backgroundKnowledge) {
                items.push(messages.missingBackgroundKnowledge);
            }

            if (metadata.subjectTags.length === 0) { items.push(messages.missingSubject); }
            if (!this.state.newImage && !metadata.imageUrl) { items.push(messages.missingFeaturedImage); }
        }
        return items;
    }

    /**
     * Save the item and redirect to the newly saved item.
     */
    @bind private async saveItem(callback?: (isSaving: boolean) => void, onRequiredFields?: (items: MessageDescriptor[]) => void) {
        if (callback) { callback(true); }
        if (!this.requiredFieldsComplete()) {
            const privateItems = this.getIncompletePrivateFieldsMessage();
            const publicItems = this.getIncompletePublicFieldsMessage();
            if (onRequiredFields) {
                const items = [...privateItems, ...publicItems];
                onRequiredFields(items);
            }
            else { this.renderModalWithIncompleteFields(privateItems, publicItems); }
        }
        else if ((this.state.blockEditorState?.blockType === XBlockType.Video
                    || this.state.blockEditorState?.blockType === XBlockType.LXVideo
                    || this.state.blockEditorState?.blockType === XBlockType.AnnotatedVideo)
                     && !this.state.isSavingChanges) {
            /// After verify the mandatory fields, this verifies the transcriptions
            /// to show an alert if there is no transcriptions
            if (Object.keys(this.state.blockEditorState?.transcripts).length === 0) {
                this.setState({
                    showWarnings: true,
                });
                const messageBody = <WrappedMessage message={messages.saveWithoutTranscriptAlertBody}/>;
                const title = <WrappedMessage message={messages.saveWithoutTranscriptAlertTitle} />;
                showWarningMessage(messageBody, {
                    title,
                    cancelText: messages.backToEdit,
                    confirmText: messages.saveAnyway,
                    onConfirm: () => {
                        this.setState({
                            isSavingChanges: true,
                            showWarnings: false,
                        }, () => this.saveItem(callback));
                    },
                });
                if (callback) { callback(false); }
                return;
            }
        }

        const itemId = await this.doSaveItem(false);
        if (itemId === undefined) {
            if (callback) { callback(false); }
            return;
        }

        // Check if we need to now add this to a pathway/classroom/etc.
        const addToInfo = this.getAddToInfo();
        switch (addToInfo?.type) {
            case ItemMetadataTypeEnum.Pathway: {
                const pathwayId = addToInfo.id;
                try {
                    await ItemsApi.pathwayAssignItems({
                        id: pathwayId,
                        data: [itemId],
                    });
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItem} />,
                        {exception: err},
                    );
                }
                this.setState({
                    isSavingChanges: false,
                    redirectTo: ROUTES.Library.PATHWAY_EDIT_SLUG(pathwayId),
                });
                return;
            }

            case ItemMetadataTypeEnum.Assignment: {
                try {
                    await ItemsApi.addAssessmentToAssignment({
                        id: addToInfo.id,
                        data: {
                            assessmentId: itemId,
                        }
                    });
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItemToAssignment} />,
                        {exception: err},
                    );
                }

                this.setState({
                    isSavingChanges: false,
                    redirectTo: ROUTES.Library.ITEM_EDIT_SLUG(addToInfo.id),
                });
                return;
            }

            case ItemMetadataTypeEnum.CaseStudy: {
                try {
                    await ItemsApi.addItemToCaseStudy({
                        id: addToInfo.id,
                        data: {
                            itemId,
                            sectionId: addToInfo.sectionId,
                        },
                    });
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItemToCaseStudy} />,
                        {exception: err},
                    );
                }

                this.setState({
                    isSavingChanges: false,
                    redirectTo: ROUTES.Library.ITEM_EDIT_SLUG(addToInfo.id),
                });
                return;
            }

            case ItemMetadataTypeEnum.TeachingGuide: {
                try {
                    await ItemsApi.addItemToTeachingGuide({
                        id: addToInfo.id,
                        data: {
                            itemId,
                            sectionId: addToInfo.sectionId,
                            embed: Boolean(addToInfo.embed),
                        },
                    });
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItemToTeachingGuide} />,
                        {exception: err},
                    );
                }

                this.setState({
                    isSavingChanges: false,
                    redirectTo: ROUTES.Library.ITEM_EDIT_SLUG(addToInfo.id),
                });
                return;
            }

            default: {
                this.setState({isSavingChanges: false});
                // Redirect to view the updated item
                this.setState({redirectTo: ROUTES.Library.ITEM_SLUG(itemId)});
            }
        }
        if (callback) { callback(false); }
    }

    private isNewItem() {
        // A new item never has an item key but always has a type
        // But it can had a `ItemMetadata` in the state while some saving steps are being
        // performed, so we look if `isSabingChanges` is flagged before we change this flag.
        return Boolean(
            ( !this.props.match.params.itemKey && this.props.match.params.itemType ) && (
                !this.state.itemMetadata?.id || this.state.isSavingChanges
            )
        );
    }

    /*
     * Has the user completed all the required fields?
     */
    private requiredFieldsComplete(): boolean {
        const metadata = this.state.itemMetadata;
        if (metadata === undefined) {
            return false;
        }

        return Boolean(
            // Check required fields that are dependent on the type of item - specific to the block editor.
            (this.state.blockEditorState && areRequiredFieldsComplete(this.state.blockEditorState, metadata.isPublic ?? false))
            // Check the standard metadata fields.
            && (
                metadata.title
                && this.state.agreementsChecked
                && metadata.language
                && (!metadata.isPublic || (
                        metadata.description
                        && metadata.freeFormTags !== undefined && metadata.freeFormTags.length > 0
                        && metadata.subjectTags !== undefined && metadata.subjectTags.length > 0
                        && (this.shouldShowBackgroundKnowledgeField() === false || metadata.backgroundKnowledge)
                        && (this.state.newImage || metadata.imageUrl)
                    )
                )
            )
        );
    }

    // return true if everything is ok to publish (including required fields
    // complete)
    private canPublish(): boolean {
        return this.requiredFieldsComplete();
    }

    private isSaveButtonDisabled(): boolean {
        // Note: if save button is enabled, it does not necessarily mean that
        // publishing will succeed. See `canPublish()`.
        return this.state.loading || this.state.isSavingChanges;
    }
}

export const ItemEditPage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        loggedInUserFullName: getUserFullName(state),
    }),
)(ItemEditPageInternal);
