import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
    links: { label: string, path?: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ links }) => (
    <nav>
        {links.map((link, index) => (
            <span key={index}>
                {link.path ? <Link to={link.path}>{link.label}</Link> : <span>{link.label}</span>}
                {index < links.length - 1 && ' / '}
            </span>
        ))}
    </nav>
);

export default Breadcrumb;
