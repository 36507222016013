import React from 'react';

interface SkillCardProps {
    image: string;
    title: string;
    description: string;
    link: string;
}

const SkillCard: React.FC<SkillCardProps> = ({ image, title, description, link }) => (
    <div className='skill-card'>
        <div className='skill-card__image'>
            <img src={image} alt={title} />
        </div>
        <div className='skill-card__content'>
            <h3 className='skill-card__title'>{title}</h3>
            <p className='skill-card__description'>{description}</p>
            <a className='skill-card__link' href={link} target='_blank' rel='noopener noreferrer'>
                Try it out <span className='skill-card__link-icon'>↗</span>
            </a>
        </div>
    </div>
);

export default SkillCard;
