import bind from 'bind-decorator';
import { Icon } from 'elements';
import { ItemsApi } from 'global/api';
import update from 'immutability-helper';
import * as React from 'react';
import { TranscriptLanguageSelector } from 'ui/components';
import { WrappedMessage } from 'utils';
import displayMessages from './displayMessages';
import { AnnotatedVideoEditorState, LXVideoEditorState, VideoEditorState } from './models';
import { intl } from 'i18n';
import { LxConfig } from '../../../global/constants';

interface TranscriptEditorProps {
    disabledLanguages: string[];
    language?: string;
    file?: File | string;
    url?: string;
    onSetLanguage: (lang: string) => void;
    onSetFile: (file?: File) => void;
}

export class TranscriptEditor extends React.PureComponent<TranscriptEditorProps> {

    private inputRef: React.RefObject<HTMLInputElement>;
    private objectUrl?: string;

    constructor(props: TranscriptEditorProps) {
        super(props);
        this.inputRef = React.createRef();
    }

    public componentWillUnmount() {
        if (this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }
    }

    public render() {
        let filename = '';
        if (this.props.file) {
            if (typeof this.props.file === 'string') {
                filename = this.props.file;
            } else {
                filename = this.props.file.name;
            }
        }

        const delBtnLabel = intl.formatMessage(displayMessages.transcriptEditorDeleteButtonLabel);

        return (
            <div className='transcript-editor'>
                <input type='file' accept='.srt, text/srt' aria-hidden={true} tabIndex={-1}
                    ref={this.inputRef} onChange={this.onFileChange}
                />
                <TranscriptLanguageSelector value={this.props.language || ''} onUpdate={this.onLanguageSelect}
                    disabledLanguages={this.props.disabledLanguages} prompt={!this.props.language} />
                {this.props.file ? (
                    <>
                        <span className='transcript-filename'>
                            {filename}
                        </span>
                        <button className='change-transcript btn btn-link' onClick={this.triggerFileDialog}>
                            <Icon name='sync'/>
                            <WrappedMessage message={displayMessages.transcriptEditorChangeButtonLabel} />
                        </button>
                        <a className='download-transcript btn btn-link' target='_blank'
                            rel='noopener noreferrer'
                            href={this.downloadUrl}>
                            <Icon name='cloud-download'/>
                            <WrappedMessage message={displayMessages.transcriptEditorDownloadButtonLabel} />
                        </a>
                        <button className='delete-transcript btn btn-link'
                            onClick={this.removeTranscript} title={delBtnLabel} aria-label={delBtnLabel}>
                            <Icon name='trashcan' /> <span className='d-md-none'>{delBtnLabel}</span>
                        </button>
                    </>
                ) : (
                    <button className='upload-transcript btn btn-link' onClick={this.triggerFileDialog}>
                        <Icon name='cloud-upload'/>
                        <WrappedMessage message={displayMessages.transcriptEditorUploadButtonLabel} />
                    </button>
                )}
            </div>
        );
    }

    private get downloadUrl() {
        // tslint:disable-next-line:no-console
        console.log('file', this.props.file); console.log('url', this.props.url);
         if (this.props.file instanceof Blob) {
            if (this.objectUrl) {
                URL.revokeObjectURL(this.objectUrl);
            }
            this.objectUrl = URL.createObjectURL(this.props.file);
            return this.objectUrl;
        } else if (this.props.url) {
            return `${LxConfig.BackendBaseUrl}${this.props.url}`;
        }
        return '';
    }

    @bind private onLanguageSelect(language: string) {
        this.props.onSetLanguage(language);
    }

    @bind private triggerFileDialog() {
        const input = this.inputRef.current;
        if (input) {
            input.click();
        }
    }

    @bind private onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        const files = e.target.files;
        if (files === null || files.length === 0) {
            return;
        }
        this.props.onSetFile(files[0]);
    }

    @bind removeTranscript() {
        this.props.onSetFile(undefined);
    }

}

interface TranscriptEditorsProps {
    editorState: VideoEditorState|AnnotatedVideoEditorState;
    onEditorStateChanged: (newState: VideoEditorState|AnnotatedVideoEditorState) => void;
}

interface TranscriptEditorsState {
    newLanguage?: string;
    newFile?: File;
}

export class TranscriptEditors extends React.PureComponent<TranscriptEditorsProps, TranscriptEditorsState> {

    constructor(props: TranscriptEditorsProps) {
        super(props);
        this.state = {};
    }

    public render() {
        const transcripts = this.props.editorState.transcripts || {};
        const transcriptsUrls = this.props.editorState.transcripts_urls || {};
        const existingLanguages = Object.keys(transcripts);
        const disabledLanguages = existingLanguages;
        if (this.state.newLanguage) {
            // XXX: this probably doesn't work, because concat doesn't modify the array
            disabledLanguages.concat([this.state.newLanguage]);
        }

        return (
            <>
                {existingLanguages.map((language) => (
                    <TranscriptEditor
                        key={language}
                        language={language}
                        file={transcripts[language]}
                        url={transcriptsUrls[language]}
                        disabledLanguages={disabledLanguages.filter((lang) => lang !== language)}
                        onSetLanguage={(newLang) => this.onSetExistingLanguage(language, newLang)}
                        onSetFile={(newFile) => this.onSetExistingFile(language, newFile)}
                    />
                ))}
                {existingLanguages.length === 0 &&
                    <TranscriptEditor key='new-transcript' disabledLanguages={existingLanguages}
                        language={this.state.newLanguage} file={this.state.newFile}
                        onSetLanguage={(newLang) => this.onSetNewLanguage(newLang)}
                        onSetFile={(newFile) => this.onSetNewFile(newFile)} />
                }
            </>
        );
    }

    @bind private onSetExistingLanguage(language: string, newLanguage: string) {
        // When changing the language of an existing transcript, we need to be careful to
        // maintain the insertion order of the transcripts object, so that the transcript field
        // corresponding to the change stays in the same position in the UI.
        const oldTranscripts = this.props.editorState.transcripts;
        const oldKeys = Object.keys(oldTranscripts);
        const transcripts: {[lang: string]: File | string} = {};
        oldKeys.forEach((key) => {
            transcripts[key === language ? newLanguage : key] = oldTranscripts[key];
        });
        const newEditorState = update(this.props.editorState, {transcripts: {$set: transcripts}});
        this.props.onEditorStateChanged(newEditorState);
    }

    @bind private onSetExistingFile(language: string, newFile?: File) {
        let newEditorState;
        if (newFile) {
            newEditorState = update(this.props.editorState, {transcripts: {[language]: {$set: newFile}}});
        } else {
            newEditorState = update(this.props.editorState, {transcripts: {$unset: [language]}});
        }
        this.props.onEditorStateChanged(newEditorState);
    }

    @bind private onSetNewLanguage(newLanguage: string) {
        this.setNewState(newLanguage, this.state.newFile);
    }

    @bind private onSetNewFile(newFile?: File) {
        if (newFile) {
            this.setNewState(this.state.newLanguage, newFile);
        } else {
            this.setState({newFile: undefined});
        }
    }

    private setNewState(newLanguage?: string, newFile?: File) {
        if (newLanguage && newFile) {
            this.setState({
                newLanguage: undefined,
                newFile: undefined,
            });
            const newEditorState = update(this.props.editorState, {transcripts: {[newLanguage]: {$set: newFile}}});
            this.props.onEditorStateChanged(newEditorState);
        } else {
            this.setState({newLanguage, newFile});
        }
    }
}

export async function uploadTranscripts(
    editorState: VideoEditorState|AnnotatedVideoEditorState|LXVideoEditorState,
    xblockId: string): Promise<{}> {
    const transcripts: any = {};
    for (const language of Object.keys(editorState.transcripts)) {
        const transcript = editorState.transcripts[language];
        // adding a new file, so we have a file object to upload
        if (transcript instanceof Blob) {
            // Upload the transcript to Blockstore:
            const filename = (await ItemsApi.editXblockSaveAsset({
                id: xblockId,
                blockKey: xblockId,
                filename: `transcript-${language}.srt`,
                file: transcript as Blob,
            })).filename;
            transcripts[language] = filename;
        } else {
            // here we only have a filename, so it's a previously uploaded
            // file. We just need to keep it present so we don't
            // accidentally wipe the previously saved transcript.
            transcripts[language] = transcript;
        }
    }
    return transcripts;
}
