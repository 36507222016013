import React from 'react';
import { Career } from '../../../labxchange-client';
import {ROUTES} from '../../../global/constants';
import {useHistory} from 'react-router-dom';

interface CareerCardProps {
    career: Career;
    pronunciation: string;
    isExpanded: boolean;
    onToggleExpand: () => void;
    onClick: () => void;  // Optional click handler
}

const CareerCard: React.FC<CareerCardProps> = ({
 career,
 isExpanded,
 onToggleExpand,
}) => {
    const history = useHistory();
    const onClickCard = () => {
        if (career.isPublished) {
            history.push(ROUTES.CareerExplorer.CAREER_DETAILS_SLUG(career.slug));
        }
    };

    return (
        <button
            className={`career-card ${isExpanded ? 'career-card--expanded' : ''}`}
            onMouseEnter={onToggleExpand}
            onMouseLeave={onToggleExpand}
            onClick={onClickCard}
            aria-expanded={isExpanded} // Accessibility: indicates if the content is expanded
            type='button' // Ensure the button doesn't submit forms by default
        >
            <div className='career-card__header'>
                <h2 className='career-card__title'>{career.title}</h2>
                {/*<p className='career-card__subtitle'>{career.description}</p>*/}
            </div>

            {isExpanded && (
                <div className='career-card__expanded-content'>
                    <p className='career-card__description'>{career.description}</p>
                    <div className='career-card__tags'>
                        <span className='career-card__tag'>{`Industry: ${career.workplace}`}</span>
                        <span className='career-card__tag'>{`Type: ${career.workplace}`}</span>
                        <span className='career-card__tag'>{`Salary: ${career.maxSalary}`}</span>
                        <span className='career-card__tag'>{`Subject: ${career.careerQualifications}`}</span>
                        <span className='career-card__tag'>{`Study duration: ${career.minimumQualification}`}</span>
                    </div>
                    <button className='career-card__button'>View full profile</button>
                </div>
            )}
        </button>
);
};

export default CareerCard;
