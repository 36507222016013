// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Author,
    AuthorFromJSON,
    AuthorToJSON,
    ContentSource,
    ContentSourceFromJSON,
    ContentSourceToJSON,
    Funder,
    FunderFromJSON,
    FunderToJSON,
    ItemStats,
    ItemStatsFromJSON,
    ItemStatsToJSON,
    LearningObjective,
    LearningObjectiveFromJSON,
    LearningObjectiveToJSON,
    PartialBook,
    PartialBookFromJSON,
    PartialBookToJSON,
    ResourceRelationship,
    ResourceRelationshipFromJSON,
    ResourceRelationshipToJSON,
} from './';

/**
 * 
 * @export
 * @interface ItemMetadata
 */
export interface ItemMetadata {
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    readonly id2?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    relId?: string;
    /**
     * 
     * @type {Array<Author>}
     * @memberof ItemMetadata
     */
    authors: Array<Author>;
    /**
     * 
     * @type {Array<Funder>}
     * @memberof ItemMetadata
     */
    funders: Array<Funder>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    backgroundKnowledge: ItemMetadataBackgroundKnowledgeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    clonedFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    references: string;
    /**
     * 
     * @type {number}
     * @memberof ItemMetadata
     */
    duration?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemMetadata
     */
    freeFormTags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    imageUrl: string;
    /**
     * Number of items in this pathway/cluster (applies only to pathways/clusters/case studies).
     * @type {number}
     * @memberof ItemMetadata
     */
    itemCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    language: string;
    /**
     * 
     * @type {Array<LearningObjective>}
     * @memberof ItemMetadata
     */
    learningObjectives: Array<LearningObjective>;
    /**
     * 
     * @type {ContentSource}
     * @memberof ItemMetadata
     */
    source?: ContentSource;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    ownerIndividual?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemMetadata
     */
    subjectTags: Array<string>;
    /**
     * 
     * @type {ItemStats}
     * @memberof ItemMetadata
     */
    stats: ItemStats;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    type: ItemMetadataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    license: ItemMetadataLicenseEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadata
     */
    isPublic?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadata
     */
    uploadedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadata
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadata
     */
    publishedAt?: Date;
    /**
     * 
     * @type {PartialBook}
     * @memberof ItemMetadata
     */
    book?: PartialBook;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    translationOf?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemMetadata
     */
    sendPiiMetadata: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ItemMetadata
     */
    toBeUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ItemMetadata
     */
    sourceUrl?: string;
    /**
     * 
     * @type {Array<ResourceRelationship>}
     * @memberof ItemMetadata
     */
    resources?: Array<ResourceRelationship>;
}

export function ItemMetadataFromJSON(json: any): ItemMetadata {
    return {
        'id': json['id'],
        'id2': !exists(json, 'id2') ? undefined : json['id2'],
        'relId': !exists(json, 'rel_id') ? undefined : json['rel_id'],
        'authors': (json['authors'] as Array<any>).map(AuthorFromJSON),
        'funders': (json['funders'] as Array<any>).map(FunderFromJSON),
        'backgroundKnowledge': json['background_knowledge'],
        'clonedFrom': json['cloned_from'],
        'description': json['description'],
        'references': json['references'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'freeFormTags': json['free_form_tags'],
        'imageUrl': json['image_url'],
        'itemCount': !exists(json, 'item_count') ? undefined : json['item_count'],
        'language': json['language'],
        'learningObjectives': (json['learning_objectives'] as Array<any>).map(LearningObjectiveFromJSON),
        'source': !exists(json, 'source') ? undefined : ContentSourceFromJSON(json['source']),
        'ownerIndividual': !exists(json, 'owner_individual') ? undefined : json['owner_individual'],
        'subjectTags': json['subject_tags'],
        'stats': ItemStatsFromJSON(json['stats']),
        'title': json['title'],
        'type': json['type'],
        'license': json['license'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'uploadedDate': !exists(json, 'uploaded_date') ? undefined : new Date(json['uploaded_date']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'publishedAt': !exists(json, 'published_at') ? undefined : new Date(json['published_at']),
        'book': !exists(json, 'book') ? undefined : PartialBookFromJSON(json['book']),
        'translationOf': !exists(json, 'translation_of') ? undefined : json['translation_of'],
        'sendPiiMetadata': json['send_pii_metadata'],
        'toBeUpdated': !exists(json, 'to_be_updated') ? undefined : new Date(json['to_be_updated']),
        'sourceUrl': !exists(json, 'source_url') ? undefined : json['source_url'],
        'resources': !exists(json, 'resources') ? undefined : (json['resources'] as Array<any>).map(ResourceRelationshipFromJSON),
    };
}

export function ItemMetadataToJSON(value?: ItemMetadata): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'rel_id': value.relId,
        'authors': (value.authors as Array<any>).map(AuthorToJSON),
        'funders': (value.funders as Array<any>).map(FunderToJSON),
        'background_knowledge': value.backgroundKnowledge,
        'cloned_from': value.clonedFrom,
        'description': value.description,
        'references': value.references,
        'duration': value.duration,
        'free_form_tags': value.freeFormTags,
        'image_url': value.imageUrl,
        'item_count': value.itemCount,
        'language': value.language,
        'learning_objectives': (value.learningObjectives as Array<any>).map(LearningObjectiveToJSON),
        'source': ContentSourceToJSON(value.source),
        'owner_individual': value.ownerIndividual,
        'subject_tags': value.subjectTags,
        'stats': ItemStatsToJSON(value.stats),
        'title': value.title,
        'type': value.type,
        'license': value.license,
        'is_public': value.isPublic,
        'uploaded_date': value.uploadedDate === undefined ? undefined : value.uploadedDate.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'published_at': value.publishedAt === undefined ? undefined : value.publishedAt.toISOString(),
        'book': PartialBookToJSON(value.book),
        'translation_of': value.translationOf,
        'send_pii_metadata': value.sendPiiMetadata,
        'to_be_updated': value.toBeUpdated === undefined ? undefined : value.toBeUpdated.toISOString(),
        'source_url': value.sourceUrl,
        'resources': value.resources === undefined ? undefined : (value.resources as Array<any>).map(ResourceRelationshipToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum ItemMetadataBackgroundKnowledgeEnum {
    None = 'none',
    Some = 'some',
    Extensive = 'extensive',
    Empty = ''
}
/**
* @export
* @enum {string}
*/
export enum ItemMetadataTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}
/**
* @export
* @enum {string}
*/
export enum ItemMetadataLicenseEnum {
    LX1 = 'LX1',
    PD = 'PD',
    CCBY40 = 'CC BY 4.0',
    CCBYNC40 = 'CC BY-NC 4.0',
    CCBY30 = 'CC BY 3.0',
    CCBYNC30 = 'CC BY-NC 3.0',
    CCBY25 = 'CC BY 2.5',
    CCBYNC25 = 'CC BY-NC 2.5',
    CCBY20 = 'CC BY 2.0',
    CCBYNC20 = 'CC BY-NC 2.0',
    CCBY10 = 'CC BY 1.0',
    CCBYNC10 = 'CC BY-NC 1.0'
}


