import { defineMessages } from 'react-intl';

const messages = defineMessages({
    careerDefaultPageTitle: {
        id:'careerDefaultPageTitle',
        defaultMessage: 'Career Explorer',
        description: 'Default value for the HTML title attribute',
    },
    careerDetailsPageTitle: {
        id:'careerDetailsPageTitle',
        defaultMessage: 'Career Details - Career Explorer',
        description: 'Title for Details page.',
    },
    careerPageTitlePlaceholder: {
        id:'careerPageTitlePlaceholder',
        defaultMessage: '{title} - Career Explorer',
        description: 'Placeholder for the value of the HTML title attribute',
    },
    careerUiHeaderNavBarBrand: {
        id:'careerUiHeaderNavBarBrand',
        defaultMessage: 'Career Explorer',
        description: 'Title for Navbar.',
    },
    careerUiNavSearch: {
        id:'careerUiNavSearch',
        defaultMessage: 'Search careers',
        description: 'Placeholder text for the career explorer search box.',
    },
    careerCardComingSoonText: {
        id:'careerCardComingSoonText',
        defaultMessage: '- Coming soon! -',
        description: 'Text to display when a career is in draft status.',
    },
    careerCategoryCardDummyDescription: {
        id:'careerCategoryCardDummyDescription',
        defaultMessage: 'This is career category description',
        description: 'Text to display when a career is in draft status.',
    },
    careerResultsText: {
        id: 'careerResultsText',
        defaultMessage: '{count} result{plural}',
        description: 'Text to display for search results.',
    },
    careerCardMinQualification: {
        id: 'careerCardMinQualification',
        defaultMessage: 'Min. Qualification: ',
        description: 'Text to display for minimum qualification.',
    },
    careerCardSalaryRange: {
        id: 'careerCardSalaryRange',
        defaultMessage: 'Salary Range: ',
        description: 'Text to display for salary range.',
    },
    careerSearchPlaceholder: {
        id: 'careerSearchPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder for career search box.',
    },
    careerNavHeaderTitle: {
        id: 'careerHeaderTitle',
        defaultMessage: 'Find your dream career',
        description: 'Title for the header.',
    },
    careerNavHeaderSubtitle: {
        id: 'careerNavHeaderSubtitle',
        defaultMessage: 'Search by interest, impact, industry or any keyword or browse all careers below. You can also use the advanced filters to customise your career options.',
        description: 'Subtitle for the header.',
    },
    categoryNavHeaderSubtitle: {
        id: 'careerHeaderSubtitle',
        defaultMessage: 'Browse all career below',
        description: 'Subtitle for the header.',
    },
    careerShowingResultsLabel: {
        id: 'careerShowingResultsLabel',
        defaultMessage: 'Showing results for',
        description: 'Label for search results.',
    },
    careerNoResultsHeading: {
        id: 'careerNoResultsHeading',
        defaultMessage: 'No results found',
        description: 'Heading for no search results.',
    },
    careerNoResultsSubheading: {
        id: 'careerNoResultsSubheading',
        defaultMessage: 'Try searching for something else.',
        description: 'Subheading for no search results.',
    },
    careerTopRecommendations: {
        id: 'careerTopRecommendations',
        defaultMessage: 'Top recommendations',
        description: 'Title for top recommendations.',
    },
    careerLoadMoreButton: {
        id: 'careerLoadMoreButton',
        defaultMessage: 'Load More',
        description: 'Button to load more careers.',
    },
    careerMinQualification: {
        id: 'careerMinQualification',
        defaultMessage: 'Min qualification',
        description: 'Text to display for minimum qualification.',
    },
    careerSalaryRange: {
        id: 'careerSalaryRange',
        defaultMessage: 'Salary range',
        description: 'Text to display for salary range.',
    },
    careerWorkplace: {
        id: 'careerWorkplace',
        defaultMessage: 'Workplace',
        description: 'Text to display for career workplace.',
    },
    careerRequirements: {
        id: 'careerRequirements',
        defaultMessage: 'Requirements',
        description: 'Text to display for career requirements.',
    },
    careerQualifications: {
        id: 'careerQualifications',
        defaultMessage: 'Qualifications',
        description: 'Text to display for career qualifications.',
    },
    careerHardSkills: {
        id: 'careerHardSkills',
        defaultMessage: 'Hard Skills',
        description: 'Text to display for career hard skills.',
    },
    careerSoftSkills: {
        id: 'careerSoftSkills',
        defaultMessage: 'Soft Skills',
        description: 'Text to display for career soft skills.',
    },
    careerWhatToDoNext: {
        id: 'careerWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text to display for what to do next.',
    },
    careerRelatedCareers: {
        id: 'careerRelatedCareers',
        defaultMessage: 'Related Careers',
        description: 'Text for related careers.',
    },
    careerSideNavOverview: {
        id: 'careerSideNavOverview',
        defaultMessage: 'Overview',
        description: 'Text for the overview section in the side nav.',
    },
    careerSideNavProgression: {
        id: 'careerSideNavProgression',
        defaultMessage: 'Career progression',
        description: 'Text for the progression section in the side nav.',
    },
    careerSideNavDayInLife: {
        id: 'careerSideNavDayInLife',
        defaultMessage: 'Day in the life',
        description: 'Text for the day in the life section in the side nav.',
    },
    careerSideNavHardSkills: {
        id: 'careerSideNavHardSkills',
        defaultMessage: 'Hard skills',
        description: 'Text for the hard skills section in the side nav.',
    },
    careerSideNavSoftSkills: {
        id: 'careerSideNavSoftSkills',
        defaultMessage: 'Soft skills',
        description: 'Text for the soft skills section in the side nav.',
    },
    careerSideNavWhatToDoNext: {
        id: 'careerSideNavWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text for the what to do next section in the side nav.',
    },
    careerSideNavRelatedCareers: {
        id: 'careerSideNavRelatedCareers',
        defaultMessage: 'Related careers',
        description: 'Title for related careers section in the side nav.',
    },
    careerSuggestedAssetsExploreLearnings: {
        id: 'careerSuggestedAssetsExploreLearnings',
        defaultMessage: 'Explore learnings',
        description: 'Text for the explore learnings button in suggested assets section.',
    },
    careerActivityTitleCardIntro: {
        id: 'careerActivityTitleCardIntro',
        defaultMessage: 'Day in life of a',
        description: 'Text for the title card intro.',
    },
    careerActivityTitleCardScroll: {
        id: 'careerActivityTitleCardScroll',
        defaultMessage: 'Scroll',
        description: 'Text for the scroll text.',
    },
    careerPronunciationAmericanText: {
        id: 'careerPronunciationAmericanText',
        defaultMessage: 'In American English it sounds like',
        description: 'Text for showing how the pronunciation will sound like in American english.',
    },
    careerAlternativeNamesText: {
        id: 'careerAlternativeNamesText',
        defaultMessage: 'Also known as:',
        description: 'Text for alternative names of a career name.',
    },
    CareerDutiesHeadingText: {
        id: 'CareerDutiesHeadingText',
        defaultMessage: 'Duties',
        description: 'Text for career duties section heading.',
    },
    careerChallengeCardCTAText: {
        id: 'careerChallengeCardCTAText',
        defaultMessage: 'Practice this on LabXchange',
        description: 'Text for challenge asset CTA.',
    },
    careerTasksMainHeading: {
        id: 'careerTasksMainHeading',
        defaultMessage: 'Let\'s take a closer look',
        description: 'Text for defining heading of task',
    },
    careerTasksDescription: {
        id: 'careerTasksDescription',
        defaultMessage: 'Here are the 5 most frequent tasks you would do on a daily basis followed by a fun challenge to test your skills!',
        description: 'Text for types kind of skills needed for the career',
    },
    careerChallengeHeading: {
        id: 'careerChallengeHeading',
        defaultMessage: 'Ready for a challenge?',
        description: 'Text for indicating the test for skills challenge',
    },
    relatedCareersHeadingText: {
        id: 'relatedCareersHeadingText',
        defaultMessage: 'Related Careers',
        description: 'Text for indicating the heading for related career section',
    },
    showLessNamesText: {
        id: 'showLessNamesText',
        defaultMessage: 'Show Less',
        description: 'Text for toggle button to show user you can collapse section by showing less text',
    },
    showAllNamesText: {
        id: 'showAllNamesText',
        defaultMessage: 'Show {count} more',
        description: 'Text for toggle button to there are some more names to show',
    },
    altTextForChallengeCard: {
        id: 'altTextForChallengeCard',
        defaultMessage: 'Challenge task image',
        description: 'Alt Text for challenge card image for accessibility',
    },
    altTextForImages: {
        id: 'altTextForImages',
        defaultMessage: 'Information icon or image',
        description: 'Alt Text for image for accessibility',
    },
    altTextForIllustration: {
        id: 'altTextForIllustration',
        defaultMessage: 'Career illustration',
        description: 'Alt Text for illustration image for accessibility',
    },
    readMoreBasicSummary: {
        id: 'readMoreBasicSummary',
        defaultMessage: 'Read more',
        description: 'Text to show you can read more about this section',
    }
});

export default messages;
