import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import messages from '../displayMessages';
import { intl } from '../../i18n';

import ViewToggle, {ViewType} from './ToggleHomeView';
import CategoryHome from './CategoryHome/CategoryHome';
import { CareerHome } from '../components';
import { CareerStandardLayout } from './CareerStandardLayout';
import { CareerSearchBar} from '../components/CareerSearchBar';

import { ROUTES}  from '../../global/constants';

const HomePage: React.FC = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('title');
    const viewQuery = queryParams.get('viewType') as ViewType;
    const history = useHistory();

    const [view, setView] = useState<ViewType>(viewQuery || 'category');

    const handleViewChange = (newView: ViewType) => {
        setView(newView);
    };

    const onSearch = (searchQuery: string, viewType: ViewType) => {
        if (searchQuery) {
            history.push(`${ROUTES.CareerExplorer.HOME}?title=${searchQuery}&view=${viewType}`);
        }
    };

    const heroSection = (
        <div className='home-hero-section'>
            {query
                ?
                <>
                    <span>{intl.formatMessage(messages.careerShowingResultsLabel)}</span>
                    <h4>{query}</h4>
                </>
                :
                <>
                    <h4>{intl.formatMessage(messages.careerNavHeaderTitle)}</h4>
                    <span>
                        {view === 'category' ? intl.formatMessage(messages.categoryNavHeaderSubtitle) : intl.formatMessage(messages.careerNavHeaderSubtitle)}
                    </span>
                    <CareerSearchBar
                        onSubmit={onSearch}
                        viewType={view}
                    />
                </>
            }
        </div>
    );

    return (
        <CareerStandardLayout
            mainClassName='career-home-page'
            pageTitle={messages.careerDefaultPageTitle}
            hideSearchBar={query ? false : true}
            heroSection={heroSection}
        >
            <div className='home-page'>
                <ViewToggle view={view} onViewChange={handleViewChange}/>
                {view === 'category' && <CategoryHome/>}
                {view === 'career' && <CareerHome/>}
            </div>
        </CareerStandardLayout>
    );
};

export default HomePage;
